/** Icon */

.icon,
.icon-group {
    font-size: inherit;

    @each $color, $value in $theme_colors {
        &.#{$color} {
            color: nth($value, 1);
        }
    }

    & * {
        font-size: inherit;
    }
}


/** Icon group (append, prepend) */

.icon-group {

  .icon-group-prepend {
    margin-right: $spacing_xs;

    @each $color, $value in $theme_colors {
      &.#{$color} {
        color: nth($value, 1);
      }
    }
  }
}
