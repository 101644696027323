/** Navigation bar */

$navbar_bg_color: $color_primary;
$navbar_fc: $color_white;
$navbar_p_x: $padding_sl;
$navbar_p_y: $spacing_base;
$navbar_p: $navbar_p_y $navbar_p_x;


/** Brand */

$navbar_brand_h: 6rem;


/** Navigation link */

$navlink_fs: $fs_m;
$navlink_p_x: $padding_xs;
$navlink_p_y: $padding_xs;
$navlink_p: $navlink_p_y $navlink_p_x;
$navlink_spacing: $margin_sl;

$navlink_active_bg_color: $color_ice;
$navlink_active_fc: $color_blue_black;


/** Burger menu */

$navlink_p_y_burger: $padding_m;


/** Responsive */

$navbar_p_x_s: $padding_sm;

