/** Link */

$link_color: $color_primary;
$link_text_decoration: none;

$link_hover_color: $color_body;
$link_hover_text_decoration: none;


/** Headers */

$h1_fs: $fs_xl;
$h2_fs: $fs_l;
$h3_fs: $fs_l;
$h4_fs: $fs_m;
$h5_fs: $fs_m;

$h1_fw: $fw_important;
$h2_fw: $fw_important;
$h3_fw: $fw_important;
$h4_fw: $fw_important;
$h5_fw: $fw_normal;

$h1_lh: $lh_bigger;
$h2_lh: $lh_bigger;

$h1_mb: $spacing_l;
$h2_mb: $spacing_m;
$h3_mb: $spacing_s;
$h4_mb: $spacing_s;
$h5_mb: $margin_s;

// Responsive
$h1_fs_s: $fs_l;
$h2_fs_s: $fs_m;
$h3_fs_s: $fs_m;
$h4_fs_s: $fs_s;


/** Paragraph */

$paragraph_lh:          $lh_bigger;
$paragraph_margin:      $spacing_s;
$paragraph_text_align:  justify;


/** List */

$list_lh:           $lh_biggest;
$list_shift:        0;
$list_spacing:      0.5rem;
$list_style_type:   none;


/** Label */

$label_fw: $fw_medium;


/** Titles */

// Structure
$text_title_1_fs: 4.4rem;
$text_title_1_fw: $fw_bold;
$text_title_1_lh: 5.7rem;

$text_title_1_fs_s: 3.6rem;
$text_title_1_fw_s: $fw_bold;
$text_title_1_lh_s: 4.8rem;

$text_title_2_fs: 3.4rem;
$text_title_2_fw: $fw_bold;
$text_title_2_lh: 4.2rem;

$text_title_2_fs_s: 2.8rem;
$text_title_2_fw_s: $fw_bold;
$text_title_2_lh_s: 3.2rem;

// Mixin
@mixin text-title-1() {
  font-size: $text_title_1_fs;
  font-weight: $text_title_1_fw;
  line-height:$text_title_1_lh;
}
@mixin text-title-1-s() {
  font-size: $text_title_1_fs_s;
  font-weight: $text_title_1_fw_s;
  line-height:$text_title_1_lh_s;
}

@mixin text-title-2() {
  font-size: $text_title_2_fs;
  font-weight: $text_title_2_fw;
  line-height:$text_title_2_lh;
}
@mixin text-title-2-s() {
  font-size: $text_title_2_fs_s;
  font-weight: $text_title_2_fw_s;
  line-height:$text_title_2_lh_s;
}


/** Text */

// Structure
$text_body_1_fs: 1.8rem;
$text_body_1_fw: $fw_normal;
$text_body_1_lh: 2.7rem;

$text_body_2_fs: 1.4rem;
$text_body_2_fw: $fw_normal;
$text_body_2_lh: 2.1rem;

$text_body_3_fs: 1.8rem;
$text_body_3_fw: $fw_normal;
$text_body_3_lh: 2.7rem;

$text_body_4_fs: 1.6rem;
$text_body_4_fw: $fw_normal;
$text_body_4_lh: 2.4rem;

$text_body_5_fs: 1.4rem;
$text_body_5_fw: $fw_normal;
$text_body_5_lh: 2.1rem;

// Mixin
@mixin text-body-1() {
  font-size: $text_body_1_fs;
  font-weight: $text_body_1_fw;
  line-height: $text_body_1_lh;
}

@mixin text-body-2() {
  font-size: $text_body_2_fs;
  font-weight: $text_body_2_fw;
  line-height: $text_body_2_lh;
}

@mixin text-body-3() {
  font-size: $text_body_3_fs;
  font-weight: $text_body_3_fw;
  line-height: $text_body_3_lh;
}

@mixin text-body-4() {
  font-size: $text_body_4_fs;
  font-weight: $text_body_4_fw;
  line-height: $text_body_4_lh;
}

@mixin text-body-5() {
  font-size: $text_body_5_fs;
  font-weight: $text_body_5_fw;
  line-height: $text_body_5_lh;
}

