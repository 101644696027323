/** Root */

& {
  background-color: $free-color_white;
  color: $free-font-color-base;

  * {
    @include free-text-body-base();
  }

  box-sizing: border-box;

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  main {
    padding-bottom: $free-padding-xxxl;
  }
}
