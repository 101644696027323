@use 'sass:map';

/** Pages Student Training Book */

.student-training-book {

  .student-training-book-add-lesson-wrapper {
    margin-bottom: $free-margin-stack-l;
    display: flex;
    justify-content: flex-end;

    .student-training-book-add-lesson-form-wrapper {
      flex: 1;
    }
  }

  .student-training-book-skills {
    margin-bottom: $free-margin-stack-l;

    .student-training-book-skills-tabs {
      align-items: flex-end;
      column-gap: $free-margin-inline-s;
      display: flex;
      padding-left: $free-padding-xs;
      padding-right: $free-padding-xs;

      .student-training-book-skill-tab {
        border-radius: $free-border-radius-m;
      }
    }
    .student-training-book-skills-tabs-active .student-training-book-skill-tab {
      border-radius: $free-border-radius-m $free-border-radius-m 0 0;
    }
    .student-training-book-skills-tabs-tab {
      flex: 1 1 0px;
    }
    .student-training-book-skill-tab {
      align-items: center;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      padding: $free-padding-squish-s;

      &.student-training-book-skill-tab-active {
        row-gap: $free-margin-stack-s;
      }

      @each $color, $value in $driving-skills-colors {
        &.student-training-book-skill-#{$color} {
          background-color: $value;
        }
      }
    }
    .student-training-book-skill-number {
      @include free-text-heading(6, $free-font-weight-medium);
      padding-bottom: $free-padding-xs;
    }
    .student-training-book-skill-progress {
      @include free-text-heading(6);
    }
    .student-training-book-skill-tab-content {
      border: $free-border-size-thick solid transparent;
      border-radius: $free-border-radius-m;
      padding: $free-padding-stretch-s;

      @each $color, $value in $driving-skills-colors {
        &.student-training-book-skill-#{$color} {
          border-color: $value;
        }
      }
    }
    .student-training-book-skill-legend {
      column-gap: $free-margin-inline-s;
      display: flex;
      justify-content: flex-end;
      margin-bottom: $free-margin-stack-xs;
      opacity: 0.75;
    }
    .student-training-book-skill-name {
      @include free-text-heading(6, $free-font-weight-medium);
      margin-bottom: $free-margin-stack-xs;
    }
    .student-training-book-sub-skills {
      display: flex;
      flex-direction: column;
      row-gap: $free-margin-stack-xs;
    }
    .student-training-book-sub-skill {
      align-items: flex-start;
      display: flex;
      column-gap: $free-margin-inline-s;
    }
    .student-training-book-sub-skill-number {
      min-width: 2.4rem;
    }
    .student-training-book-sub-skill-name {
      flex-grow: 1;
    }
    .student-training-book-sub-skill-status {
      background-color: $free-color-white;
      border: $free-border-size-normal solid $free-color-black;
      border-radius: $free-border-radius-s;
      height: 2.4rem;
      position: relative;
      min-width: 2.4rem;
      max-width: 2.4rem;

      &.student-training-book-sub-skill-status-broached {
        background: linear-gradient(
            to bottom right,
            transparent calc(50% - 1px),
            $free-color-black calc(50% - 1px),
            $free-color-black calc(50% + 1px),
            transparent calc(50% + 1px)
        );
      }
      &.student-training-book-sub-skill-status-covered {
        background:
          linear-gradient(
              to bottom right,
              transparent calc(50% - 1px),
              $free-color-black calc(50% - 1px),
              $free-color-black calc(50% + 1px),
              transparent calc(50% + 1px)
          ),
          linear-gradient(
              to bottom left,
              transparent calc(50% - 1px),
              $free-color-black calc(50% - 1px),
              $free-color-black calc(50% + 1px),
              transparent calc(50% + 1px),
          );
      }
      &.student-training-book-sub-skill-status-assimilated::after {
        background: $free-color-black;
        border-radius: $free-border-radius-s;
        content: '';
        position: absolute;
        bottom: 2px;
        left: 2px;
        right: 2px;
        top: 2px;
      }
    }
  }

  .student-training-book-evaluation {

    strong {
      font-weight: $free-font-weight-bold;
    }
  }
  .student-training-book-evaluation-actions {
    display: flex;
    flex-direction: column;
    margin-top: $free-margin-stack-s;
    row-gap: $free-margin-stack-s;
  }
  .student-training-book-evaluation-form-actions {
    column-gap: $free-margin-inline-m;
    display: flex;
    margin-top: $free-margin-stack-s;
  }

  .student-training-book-list:not(:last-of-type) {
    margin-bottom: $free-margin-stack-xl;
  }

  .student-training-book-list-header {
    margin-bottom: $free-margin-stack-m;
  }

  .student-training-book-list-title {
    @include free-text-heading(5, $free-font-weight-medium);
  }

  .student-training-book-list-total {
    @include free-text-heading(6);
    color: map.get($free-palette-neutral, 500);
  }

  .student-training-book-list-item:not(:last-of-type) {
    margin-bottom: $free-margin-stack-s;
  }

  @media all and (min-width: $free-breakpoint-m)
  {
    .student-training-book-skills .student-training-book-skills-tabs {
      column-gap: $free-margin-inline-m;
      padding-left: $free-padding-s;
      padding-right: $free-padding-s;
    }
  }
}
