/** Base color */

$color_apple:           #A5FACD;
$color_apple_dark:      #00BF35;
$color_apple_light:     #EEFFF6;
$color_black:           #000000;
$color_blue_black:      #2F2E33;
$color_dark_gray:       #6C757D;
$color_glacier_bleu:    #00A3B4;
$color_gray:            #888888;
$color_green:           #28A745;
$color_ice:             #B0E0E6;
$color_ice_light:       #DCF4F6;
$color_light_gray:      #F8F8F8;
$color_light_green:     #D4EDDA;
$color_light_red:       #F8D7DA;
$color_medium_gray:     #D9D9D9;
$color_orange:          #FF6F00;
$color_red:             #E62638;
$color_turquoise:       #41D1CC;
$color_turquoise_dark:  #0C343D;
$color_warm_gray:       #BCBABE;
$color_white:           #FFFFFF;
$color_yellow:          #FFCC00;
$color_yellow_light:    #FFED00;
$color_yellow_pale:     #FFFCD2;
$color_green_bolt:      #00B91C;

$colors: (
    "blue-black":   $color_blue_black,
    "dark-gray":    $color_dark_gray,
    "glacier-bleu": $color_glacier_bleu,
    "green":        $color_green,
    "ice":          $color_ice,
    "light-gray":   $color_light_gray,
    "light-green":  $color_light_green,
    "light-red":    $color_light_red,
    "medium-gray":  $color_medium_gray,
    "orange":       $color_orange,
    "red":          $color_red,
    "warm-gray":    $color_warm_gray,
    "white":        $color_white,
    "yellow":       $color_yellow
);


/** Layout color */

$color_bg:          $color_white;
$color_body:        $color_blue_black;
$color_separator:   $color_medium_gray;
$color_label:       $color_dark_gray;


/** Theme color */

$color_cancel:      $color_medium_gray;
$color_checked:     $color_apple_dark;
$color_danger:      $color_orange;
$color_dark:        $color_blue_black;
$color_error:       $color_red;
$color_info:        $color_yellow;
$color_light:       $color_white;
$color_more:        $color_dark;
$color_none:        $color_dark_gray;
$color_primary:     $color_glacier_bleu;
$color_secondary:   $color_ice;
$color_shocking:    $color_yellow_light;
$color_success:     $color_green;
$color_warning:     $color_orange;

$theme_colors: (
  "cancel":       ($color_cancel,     $color_dark),
  "checked": ($color_apple, $color_apple_dark),
  "danger":       ($color_danger,     $color_light),
  "error":        ($color_error,      $color_light),
  "info":         ($color_info,       $color_dark),
  "more":         ($color_dark,       $color_light),
  "none":         ($color_none,       $color_light),
  "primary":      ($color_primary,    $color_light),
  "secondary":    ($color_secondary,  $color_dark),
  "shocking":     ($color_shocking,   $color_dark),
  "success":      ($color_success,    $color_light),
  "premium":      ($color_green_bolt, $color_light),
  "warning":      ($color_warning,    $color_light),
);

$color_disabled: $color_warm_gray;


/** Driving licence type colors */

$driving_licence_color_b_manual: #A1F0F9;
$driving_licence_color_b_auto: #C9EFAB;
$driving_licence_color_b_accompanied: #85CDF7;
$driving_licence_color_bva_accompanied: #B4D799;
$driving_licence_color_a: #D5CBEB;
$driving_licence_color_c: #EDB0B0;
$driving_licence_color_d: #F4D3B4;
$driving_licence_color_other: #CCE8E5;
$driving_licence_color_boat: #20B2AA;
$driving_licence_color_pap: #FF69B4;
$driving_licence_color_test: #FFB6C1;

$driving_licences_colors: (
  'b-manual': $driving_licence_color_b_manual,
  'b-auto': $driving_licence_color_b_auto,
  'b-accompanied': $driving_licence_color_b_accompanied,
  'bva-accompanied': $driving_licence_color_bva_accompanied,
  'a': $driving_licence_color_a,
  'c': $driving_licence_color_c,
  'd': $driving_licence_color_d,
  'other': $driving_licence_color_other,
  'boat': $driving_licence_color_boat,
  'pap': $driving_licence_color_pap,
  'test': $driving_licence_color_test
);


/** Training planning colors */

$training_slot_color_activity: $color_medium_gray;
$training_slot_color_driving_lesson: #00E37F;
$training_slot_color_driving_test: #FBEDA1;

$training_slot_colors: (
  'activity': $training_slot_color_activity,
  'driving-lesson': $training_slot_color_driving_lesson,
  'driving-test': $training_slot_color_driving_test
);
