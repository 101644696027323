/** Component: Student Driving Lesson Schema Drawing */

.student-driving-lesson-schema-drawing {
  background: $free-color-white;
  border: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;

  .student-schema-drawing-toolbar-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: $free-breakpoint-s;
    padding: $free-padding-squish-m;
  }

  .student-schema-drawing-toolbar {
    display: flex;
    align-items: center;
    gap: 1.6rem;
  }

  .student-schema-drawing-toolbar-color-button {
    width: 2rem;
    aspect-ratio: 1;
    background-color: var(--color);
    border: solid 1px transparent;
    border-radius: 50%;
    cursor: pointer;

    &.student-schema-drawing-toolbar-color-button-selected {
      outline: solid 2px $free-color-primary-main;
      border-color: transparent;
    }
  }

  .student-schema-drawing-toolbar-color-button-bordered {
    @extend .student-schema-drawing-toolbar-color-button;
    border-color: #000000;
  }

  .student-schema-drawing-sheet-wrapper {
    display: grid;
    gap: $free-margin-stack-s;
  }

  .student-schema-drawing-sheet-action {
    display: grid;
    place-items: center;
  }

  .student-schema-drawing-sheet {
    border: solid 1px #000000;
    cursor: crosshair;
    touch-action: none;
    margin: 0 auto;
  }

  @media (min-width: $free-breakpoint-m)
  {
    position: initial;
    top: initial;
    right: initial;
    z-index: initial;
    background: initial;
    width: initial;
  }
}
