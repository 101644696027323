@use "sass:map";

/** Dropdown Structure & Skin */

$free-dropdown-label-spacing: $free-margin-stack-xs;
$free-dropdown-list-background-color: $free-color-white;
$free-dropdown-list-border-radius: $free-border-radius-l;
$free-dropdown-list-box-field-background-color: $free-color-white;
$free-dropdown-list-box-menu-icon: $free-color-primary-main;
$free-dropdown-list-item-background-color-hover: map.get($free-palette-neutral, 50);
$free-dropdown-list-item-background-color-pressed: map.get($free-palette-neutral, 100);
$free-dropdown-list-item-background-color-selected: map.get($free-palette-neutral, 50);
$free-dropdown-list-item-cursor: pointer;

$free-dropdown-list-box-field-select-border-radius: $free-border-radius-m;
$free-dropdown-list-box-field-select-border-size: $free-border-size-normal;
$free-dropdown-list-box-field-select-placeholder-color: map.get($free-palette-neutral, 500);

/** Dropdown Size */

$free-dropdown-list-box-height-s: 3.2rem;
$free-dropdown-list-box-height-m: 4rem;
$free-dropdown-list-box-height-l: 5.6rem;

$free-dropdown-sizes: (
  "small": $free-dropdown-list-box-height-s,
  "medium": $free-dropdown-list-box-height-m,
  "large": $free-dropdown-list-box-height-l
);

$free-dropdown-list-box-height-base: $free-dropdown-list-box-height-m;
$free-dropdown-list-box-label-font-weight: $free-font-weight-regular;
$free-dropdown-list-box-num-items: 5;
$free-dropdown-list-box-padding: 0 $free-padding-s;
$free-dropdown-list-box-placeholder-heading-level: 6;
