.student-sheet-training-book-add-lesson-form {
  display: grid;
  gap: $free-margin-stack-s;

  .student-sheet-training-book-add-lesson-form-date-and-duration {
    display: flex;
    gap: $free-margin-stack-s;
  }

  .student-sheet-training-book-add-lesson-form-actions {
    display: flex;
    justify-content: flex-end;
    gap: $free-margin-stack-s;
  }
}
