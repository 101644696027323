@use 'sass:map';

/** Page: Student Training Scheduler */

.student-training-scheduler {
  display: flex;
  flex-direction: column;

  .student-training-scheduler-filter {
    margin-bottom: $free-margin-stack-s;
  }

  .student-training-basket {

    .student-training-basket-title {
      @include free-text-heading(5, $free-font-weight-medium);
      color: map.get($free-palette-primary-main, 700);
      margin-bottom: $free-margin-stack-s;
    }

    .student-training-basket-lessons:not(:first-of-type) {
      margin-top: $free-margin-stack-s;
    }

    .driving-hour {
      align-items: center;
      display: grid;
      grid-column-gap: $free-margin-inline-s;
      grid-template-columns: auto 8rem 4rem 5rem;
    }

    .student-training-basket-actions {
      display: flex;
      margin-top: $free-margin-stack-s;
    }

    .student-training-basket-action-empty {
      margin-right: $free-margin-inline-m;
    }
  }

  @media (min-width: $free-breakpoint-m) {
    align-items: flex-start;
  }

  @media (min-width: $free-breakpoint-l) {
    flex-direction: row;

    .student-training-scheduler-trainer-availabilities {
      margin-right: $free-margin-inline-l;
    }
  }
}
