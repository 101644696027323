/** Page: Auth Login */

#pageAuthLogin {

  .auth-login-wrapper {
    margin: 0 auto;
    max-width: $free-breakpoint-m;
    min-width: $free-breakpoint-s;
    padding: 0 $free-padding-s;
  }

  .auth-login-header {
    margin-bottom: $free-margin-stack-m;
    padding-top: $free-padding-l;

    h1 {
      @include free-text-heading(4, $free-font-weight-medium);
      margin-bottom: $free-margin-stack-l;
    }
  }

  fieldset {
    display: grid;
    grid-row-gap: $free-margin-stack-s;
  }

  .auth-login-form-submit {
    margin: $free-margin-stack-l 0;
  }
}
