/**
  DESIGN SYSTEM
  Freedom by Klaxo
*/

/** Reset legacy styles */

main {
  min-height: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: inherit;
  margin-bottom: 0;
}

ul {
  list-style-type: none;
  li {
    margin-bottom: 0;
    margin-left: 0;
  }
}

p {
  margin-bottom: 0;
}

input[type=email], input[type=number], input[type=tel], input[type=text], input[type=password] {
  border: none;
  border-radius: 0;
  margin-right: 0;
}

figure {
  line-height: 0;
}

img {
  border-radius: inherit;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

/** Variables **/

@import "variables/breakpoint";
@import "variables/color";
@import "variables/gradient";
@import "variables/spacing";
@import "variables/typography";
@import "variables/border";
@import "variables/opacity";
@import "variables/shadow";
@import "variables/button";
@import "variables/form";
@import "variables/dropdown";
@import "variables/confirm-action";
@import "variables/box";

/** Mixins **/

@import "mixins/breakpoint";
@import "mixins/display";
@import "mixins/text";
@import "mixins/gradient";
@import "mixins/button";
@import "mixins/layout";

/** Base **/

@import "base/font";
@import "base/root";

/** Components **/

@import "components/icon";
@import "components/text";
@import "components/button";
@import "components/form";
@import "components/dropdown";
@import "components/checkbox";
@import "components/checkbox-group";
@import "components/radio";
@import "components/text-input";
@import "components/number-input";
@import "components/date-input";
@import "components/period-input";
@import "components/pagination";
@import "components/confirm-action";
@import "components/editable-field";
@import "components/box";
@import "components/typeahead-select-input";
@import "components/date-navigation-input";
@import "components/select-button";
