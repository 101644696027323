/** Headers */

h1 {
  font-size: $h1_fs;
  font-weight: $h1_fw;
  line-height: $h1_lh;
  margin-bottom: $h1_mb;
}

h2 {
  font-size: $h2_fs;
  font-weight: $h2_fw;
  line-height: $h2_lh;
  margin-bottom: $h2_mb;
}

h3 {
  font-size: $h3_fs;
  font-weight: $h3_fw;
  margin-bottom: $h3_mb;
}

h4 {
  font-size: $h4_fs;
  font-weight: $h4_fw;
  margin-bottom: $h4_mb;
}

h5 {
  font-size: $h5_fs;
  font-weight: $h5_fw;
  margin-bottom: $h5_mb;
}


/** Paragraph */

p {
  line-height: $paragraph_lh;
  margin-bottom: $paragraph_margin;
}


/** Link */

a {
  color: $link_color;
  text-decoration: $link_text_decoration;

  &:hover {
    color:  $link_hover_color;
    text-decoration: $link_hover_text_decoration;
  }
}


/** List */

ul {
  list-style-type: $list_style_type;

  li {
    margin-bottom:  $list_spacing;
    margin-left:    $list_shift;
  }
}


/** Important */

strong {
  font-size: inherit;
  font-weight: $fw_medium;
}


/** Emphasis */

em {
  font-size:      inherit;
  font-weight:    inherit;
}

/** Theme */

span {
  @each $color, $value in $theme_colors {
    &.#{$color} {
      color: nth($value, 1);
    }
  }
}


.label {
  font-weight: $label_fw;
  line-height: 1.4;
}


/** Width media queries */

@media all and (max-width: 768px)
{
    h1 {
        font-size: $h1_fs_s;
    }

    h2 {
        font-size: $h2_fs_s;
    }

    h3 {
        font-size: $h3_fs_s;
    }

    h4 {
        font-size: $h4_fs_s;
    }
}
