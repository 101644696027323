/** Date Input */

.free-date-input {

  .free-form-label {
    margin-bottom: $free-date-input-label-spacing;
  }

  .free-form-helper-text,
  .free-form-invalid-text {
    margin-top: $free-form-invalid-text-spacing;
  }

  .free-date-inputs {
    display: flex;
  }

  .free-date-input-date {
    width: 12.8rem;
  }

  .free-date-input-time {
    margin-left: $free-margin-inline-s;
    width: 8rem;
  }

  .free-date-input-date,
  .free-date-input-time {
    font-feature-settings: "tnum";
    font-variant-numeric: tabular-nums;
  }
}
