/** Form */

fieldset {

  legend {
    @include free-text-heading($free-fieldset-legend-heading-level, $free-fieldset-legend-font-weight);
    color: $free-fieldset-legend-color;
    margin-bottom: $free-fieldset-legend-spacing;
  }
}

.free-form-label {
  @include free-text-heading($free-form-label-heading-level, $free-form-label-font-weight);
  display: inline-block;

  .free-form-sub-label {
    @include free-text-body-size('small');
  }
}

.free-text-input-form-label {
  margin-bottom: $free-text-input-label-spacing;
}

.free-form-helper-text {
  @include free-text-body-size($free-form-helper-text-body-size);
  color: $free-form-helper-text-color;
}

.free-form-invalid-text {
  @include free-text-body-size($free-form-invalid-text-body-size);
  color: $free-form-invalid-text-color;
}
