/** Panel */

.panel {
  background-color: $panel_bg_color;
  border-radius: $panel_br;
  margin-top: $panel_m_y;
  box-shadow: $box_shadow_m;

  &.panel-button button {
    width: 100%;
  }

  > .header {
    padding: $panel_padding;

    h1,
    h2 {
        margin-bottom: 0;
    }
  }

  > .body,
  > .footer {
    padding: $panel_padding;

    .actions {
      display:            flex;
      justify-content:    center;

      button {
        flex-grow: 1;
        margin-right: $spacing_m;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  &.flat {
    box-shadow: none;
  }
}

form.panel {
  padding-bottom: 0;

  button[type="submit"] {
    border-radius:  0;
    width:          100%;
  }

  > .body {
    padding: $panel_padding;
  }
}

.panel.panel-confirm,
form.panel {

  > .footer {
    padding: 0;

    .confirm-action button {
      border-radius: 0 0 $panel_br $panel_br;
    }

    .confirm-actions {
      display: flex;

      button {
        width: 50%;

        &:first-of-type {
          border-radius: 0 0 0 $panel_br;
        }

        &:last-of-type {
          border-radius: 0 0 $panel_br 0;
        }
      }
    }
  }
}

.panel.panel-group {
    display:                grid;
    grid-template-columns:  4rem 1fr;

    > .icon {
      color:      $color_dark_gray;
      font-size:  $fs_l;
      padding:    $panel_padding;
    }

    .header,
    .body {
        grid-column-start: 2;
    }
}


/** Listing */

.list-cards {

  .list-cards-title {
    font-size: $fs_m;
  }

  .list-cards-number {
    color: $color_dark_gray;
  }
}


/** Card */

.card {
  background-color: $card_bg_color;
  border-radius: $card_br;
  overflow: hidden;
  box-shadow: $box_shadow_m;

  @each $color, $value in $theme_colors {
    &.#{$color} .header {
      background-color: nth($value, 1);
      color:            nth($value, 2);
    }
  }

  .header {
    font-size:        $card_header_fs;
    font-weight:      $card_header_fw;
    padding:          $card_padding;
  }

  .body {
    padding: $card_padding;
  }

  .footer {
    display: flex;
    justify-content: center;
    padding: $card_padding;
    padding-top: 0;
  }

  &.confirm .footer {
    padding: 0;

    button {
      border-radius:  0 0 $card_br $card_br;
      width:          100%;
    }
  }

  &.confirm-cancel .footer {
    padding: 0;
    padding-top: $card_padding;

    .confirm-actions {
      display: flex;
      width: 100%;

      button {
        width: 50%;

        &:first-of-type {
          border-radius: 0 0 0 $card_br;
        }

        &:last-of-type {
          border-radius: 0 0 $card_br 0;
        }
      }
    }
  }

  &.flat {
    box-shadow: none;
  }
}


/** Inline panel */

.inline-panel {
  background-color: $panel_bg_color;
  border-radius: $panel_br;
  box-shadow: $panel_bs;
  padding: $padding_sm;

  &.flat {
    box-shadow: none;
  }
}
