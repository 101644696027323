@use "sass:map";

/** Confirm Action */

$free-confirm-action-box-background-color: $free-color-white;
$free-confirm-action-box-border-color: map.get($free-palette-neutral, 200);
$free-confirm-action-box-border-radius: $free-border-radius-m;
$free-confirm-action-box-border-size: 1px;
$free-confirm-action-box-padding: $free-padding-inset-s;
$free-confirm-action-box-spacing: $free-margin-stack-xs;
$free-confirm-action-box-width: auto;
