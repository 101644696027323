@use "sass:map";

$student-training-book-online-evaluation-card-details-box-body-gap: 2rem;
$category-score-border-size: 1px;

.student-training-book-online-evaluation-card {

  &.driving-evaluation-done .driving-evaluation-date {
    color: map.get($free-palette-neutral, 400);
  }

  .student-training-book-online-evaluation-card-main {
    column-gap: 3.2rem;
    display: grid;
    grid-template-areas:
      "evalType actions"
      "evalDate actions"
      "toggle toggle";
    grid-template-columns: 1fr 4rem;

    .driving-evaluation-type {
      @include free-text-heading(6, $free-font-weight-medium);
      grid-area: evalType;
    }
    .student-training-book-online-evaluation-card-actions {
      grid-area: actions;
    }
    .driving-evaluation-date {
      @include free-text-heading(6, $free-font-weight-medium);
      grid-area: evalDate;
    }
    .student-training-book-online-evaluation-card-toggle {
      grid-area: toggle;
      justify-self: center;
    }
  }

  .student-training-book-online-evaluation-card-details {
    display: flex;
    flex-direction: column;
    margin-top: $free-margin-stack-s;

    .student-training-book-online-evaluation-card-details-top-text {
      @include free-text-heading(5, $free-font-weight-medium);
      margin-bottom: $free-margin-stack-l;
    }

    .student-training-book-online-evaluation-card-details-box {
      display: grid;
      gap: 2rem;

      .student-training-book-online-evaluation-card-details-box-header {
        background: #E5EDFF;
        color: #1703F8;
        padding: $free-padding-squish-s;
      }

      .student-training-book-online-evaluation-card-details-box-body {
        display: grid;
        gap: $student-training-book-online-evaluation-card-details-box-body-gap;
        margin-bottom: $free-margin-stack-l;

        .student-training-book-online-evaluation-card-details-box-body-column {
          display: flex;
          align-items: start;
          flex-direction: column;
          gap: $free-padding-xs;

          &:not(:last-child) {
            padding-bottom: $student-training-book-online-evaluation-card-details-box-body-gap;
            border-bottom: 1px solid map.get($free-palette-neutral, 300);
          }
        }
      }

      .student-training-book-online-evaluation-card-details-box-body-column-title,
      .student-training-book-online-evaluation-card-details-box-body-category-title,
      .student-training-book-online-evaluation-card-details-box-body-column-item-title {
        font-weight: $free-font-weight-medium;
        color: $free-color-black;
      }

      .student-training-book-online-evaluation-card-details-box-body-driving-licence-type-wrapper {
        display: grid;
        gap: $free-padding-xs;
      }

      .student-training-book-online-evaluation-card-details-box-body-column-item-checkbox-wrapper {
        display: grid;
        grid-template-columns: 1.2rem 1fr;
        align-items: center;
        gap: $free-padding-xs;

        .student-training-book-online-evaluation-card-details-box-body-column-item-checkbox {
          width: 1.2rem;
          height: 1.2rem;
          outline: 1px solid map.get($free-palette-primary-main, 500);
          border-radius: 3px;

          &.student-training-book-online-evaluation-card-details-box-body-column-item-checkbox-checked {
            background: map.get($free-palette-primary-main, 500);
          }
        }
      }
    }

    .student-training-book-online-evaluation-card-details-box-body-simple {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: $free-padding-xs;
    }

    .student-training-book-online-evaluation-card-details-box-body-category {
      display: grid;
      gap: 1.2rem;
    }

    .student-training-book-online-evaluation-card-details-box-body-category-score-value,
    .student-training-book-online-evaluation-card-details-box-body-category-score {
      display: flex;
      height: 1.2rem;
      outline: $category-score-border-size solid map.get($free-palette-neutral, 300);
      border-radius: $free-border-radius-round;
      position: relative;
    }

    .student-training-book-online-evaluation-card-details-box-body-category-score-value {
      opacity: var(--scoreOpacity);
    }

    .student-training-book-online-evaluation-card-details-box-body-category-score-text {
      text-align: center;
    }

    .student-training-book-online-evaluation-card-details-box-body-category-score {
      margin: auto auto 0;
    }

    .student-training-book-online-evaluation-card-details-box-body-category-score-value {
      width: calc(100% * var(--scorePercent));
      background: map.get($free-palette-success, 300);
      outline-color: map.get($free-palette-success, 300);
    }

    .student-training-book-online-evaluation-card-details-estimation {
      display: grid;
      gap: $free-margin-stack-l;
      margin-top: $free-margin-stack-l;

      .student-training-book-online-evaluation-card-details-estimation-text {
        @include free-text-heading(5, $free-font-weight-medium);
      }

      .student-training-book-online-evaluation-card-details-estimation-sub-text {
        @include free-text-heading(6, $free-font-weight-medium);
        color: $free-color-black;
      }

      .student-training-book-online-evaluation-card-details-estimation-actions {
        display: flex;
        gap: $free-margin-stack-l;
      }
    }

    @media all and (min-width: $free-breakpoint-l) {
      .student-training-book-online-evaluation-card-details-box {
        .student-training-book-online-evaluation-card-details-box-body {
          display: flex;

          .student-training-book-online-evaluation-card-details-box-body-column {
            flex: 1;

            &:not(:last-child) {
              padding-bottom: 0;
              border-bottom: none;

              padding-right: $student-training-book-online-evaluation-card-details-box-body-gap;
              border-right: 1px solid map.get($free-palette-neutral, 300);
            }
          }
        }

        .student-training-book-online-evaluation-card-details-box-body-simple {
          flex-direction: row;

          .student-training-book-online-evaluation-card-details-box-body-category-score {
            width: 9.2rem;
          }
        }
      }
    }
  }

  @media all and (min-width: $free-breakpoint-m) {
    .student-training-book-online-evaluation-card-main {
      align-items: center;
      column-gap: 0;
      grid-template-areas:"evalType evalDate actions toggle";
      grid-template-columns: 24rem 1fr 4rem min-content;
    }
  }
}
