/** Border radius */

$free-border-radius-s: 0.4rem;
$free-border-radius-m: 0.8rem;
$free-border-radius-l: 2.4rem;
$free-border-radius-xl: 3.6rem;
$free-border-radius-xxl: 4.8rem;

$free-border-radius-round: 1000rem;

/** Border Size */

$free-border-size-normal: 1px;
$free-border-size-thick: 2px;
$free-border-size-very-thick: 4px;
