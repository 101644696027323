@use "sass:map";

/** Base Button Mixin **/

@mixin free-button-base() {
  @include free-text-heading($free-button-heading-level-base, $free-button-font-weight-base);

  align-items: center;
  background: $free-button-background-color-base;
  border: $free-button-border-base;
  border-radius: $free-button-border-radius-base;
  color: $free-button-color-base;
  cursor: $free-button-cursor-base;
  display: inline-flex;
  height: $free-button-height-base;
  justify-content: center;
  outline: none;
  padding: $free-button-padding-base;
  text-decoration: none;

  &[disabled],
  &.free-btn-disabled {
    cursor: default;
    opacity: $free-opacity-disabled;
  }

  &.free-btn-icon {
    padding: 0;
    justify-content: center;
    width: $free-button-height-base;
  }

  &.free-btn-block {
    width: 100%;
  }

  .free-btn-leading-icon {
    margin-right: $free-button-icon-spacing;
  }

  .free-btn-label {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }

  .free-btn-sub-label {
    color: inherit;
    font-family: inherit;
    font-size: smaller;
    font-weight: inherit;
    line-height: inherit;
  }

  .free-btn-trailing-icon {
    margin-left: $free-button-icon-spacing;
  }

  .free-icon {
    min-width: map.get($free-heading-line-heights, $free-button-heading-level-base);
  }
}

/** Button Type Mixins */

%free-button-state-active-placeholder {
  transform: $free-button-transform-active;
  transition: $free-button-transition-active;
}

@mixin free-button-primary() {
  background: $free-button-primary-background-color-default;
  color: $free-button-primary-color-default;

  &:not([disabled]):not(.free-btn-disabled) {

    &:focus:not(.free-btn-flat) {
      box-shadow: $free-button-primary-shadow-focused;
    }

    &:hover {
      background: $free-button-primary-background-color-hover;
    }

    &:active {
      @extend %free-button-state-active-placeholder;
    }
  }
}

@mixin free-button-secondary() {
  background: $free-button-secondary-background-color;
  border-color: $free-button-secondary-color-default;
  color: $free-button-secondary-color-default;

  &:not([disabled]):not(.free-btn-disabled) {

    &:focus:not(.free-btn-flat) {
      box-shadow: $free-button-secondary-shadow-focused;
    }

    &:hover {
      border-color: $free-button-secondary-color-hover;
      color: $free-button-secondary-color-hover;
    }

    &:active {
      @extend %free-button-state-active-placeholder;
    }
  }
}

@mixin free-button-tertiary() {
  background: $free-button-tertiary-background-color;
  border: $free-button-tertiary-border;
  color: $free-button-tertiary-color-default;

  &:not([disabled]):not(.free-btn-disabled) {

    &:hover {
      color: $free-button-tertiary-color-hover;
    }

    &:active {
      color: $free-button-tertiary-color-pressed;
    }
  }
}

@mixin free-button-cta() {
  background: $free-button-cta-background-color-default;
  border: none;
  color: $free-button-cta-color;

  &:not(.free-btn-flat) {
    box-shadow: $free-button-cta-shadow-default;
  }

  &:not([disabled]):not(.free-btn-disabled) {

    &:focus {
      background: $free-button-cta-background-color-focused;

      &:not(.free-btn-flat) {
        box-shadow: $free-button-cta-shadow-focused;
      }
    }

    &:hover {
      background: $free-button-cta-background-color-hover;
    }

    &:active {
      @extend %free-button-state-active-placeholder;
    }
  }
}

/** Button Custom Colors */

@mixin free-button-primary-colors(
  $bgColorDefault: $free-button-primary-background-color-default,
  $bgColorHover: $free-button-primary-background-color-hover,
  $textColor: $free-button-primary-color-default
) {
  background: $bgColorDefault;
  color: $textColor;

  &:not([disabled]):not(.free-btn-disabled) {

    &:hover {
      background: $bgColorHover;
    }
  }
}

@mixin free-button-tertiary-colors(
  $textColor: $free-button-tertiary-color-default,
  $textColorHover: $free-button-tertiary-color-hover,
) {
  color: $textColor;

  &:not([disabled]):not(.free-btn-disabled) {

    &:hover {
      color: $textColorHover;
    }
  }
}
