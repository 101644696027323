@use "sass:math";

/** Agency and training plannings */

$slot_color_available: #00E37F;
$slot_color_unavailable: #EFEFEF;
$slot_color_schedule: #9AEAF2;
$slot_color_cancelled: #FF0000;

$slot_p: $spacing_xs;
$slot_fs: $fs_s;
$slot_height: $slot_fs + ($slot_p * 2);

$planning_cell_p_y: $spacing_ms;
$planning_cell_p_x: $spacing_xs;
$planning_cell_p: $planning_cell_p_y $planning_cell_p_x;

$driving_test_color: rgba(0, 163, 180, 0.5);

@mixin drivingLicenceColor() {
  &.driving-licence-b-manual {
    background-color: #A1F0F9;
  }

  &.driving-licence-b-auto {
    background-color: #C9EFAB;
  }

  &.driving-licence-b-accompanied {
    background-color: #85CDF7;
  }

  &.driving-licence-a {
    background-color: #D5CBEB;
  }

  &.driving-licence-c {
    background-color: #EDB0B0;
  }

  &.driving-licence-d {
    background-color: #F4D3B4;
  }

  &.driving-licence-other {
    background-color: #CCE8E5;
  }

  &.driving-test {
    background-color: #FBEDA1;
  }
}

@mixin slotStatusColor() {
  &.available {
    background-color: $slot_color_available;
    color: $color_dark;
  }

  &.unavailable {
    background-color: $slot_color_unavailable;
    color: $color_dark;
  }

  &.booked {
    @include drivingLicenceColor();
  }

  &.not-happened {
    background-color: $slot_color_cancelled;
  }

  .not-happened {
    color: $slot_color_cancelled;
  }
}

@mixin slotStatus() {
  text-transform: none;
  padding: $slot_p;
  border-radius: $br_infinite;
  @include slotStatusColor();
}

