/** Structure */

button {
  margin: 0;
}

button,
a.btn {
  @include btn();

  &:focus {
    outline: 0;
  }

  &[disabled] {
    opacity: $btn_disabled_opacity;
    cursor: default;
  }

  &.link {
    padding: 0 $btn_p_x;
  }
}

.confirm-actions {
  display: flex;

  button {
    margin: 0;
  }

  button:first-of-type {
    border-radius: $btn_br 0 0 $btn_br;
  }

  button:last-of-type {
    border-radius: 0 $btn_br $btn_br 0;
  }
}


/** Skin */

button,
a.btn {
  @include btn-solid();

  &.btn-outline {
    @include btn-outline();
  }

  &.link {
    @include btn_link();
  }
}


// TODO: clean
button {
  @each $color, $value in $theme_colors {
    &.select.active.#{$color} {
      background-color: nth($value, 2);
      color: nth($value, 1);
      border: 1px solid nth($value, 1);
    }
  }
}
