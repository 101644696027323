/** Footer */

footer {
  background-color: $color_white;

  .footer-content {
    display: grid;
    gap: $margin_l;
    grid-template-columns: repeat(3, 1fr);
    margin: auto;
    padding: $padding_xxl $padding_sl;
    max-width: 140rem;
  }

  .footer-links-group-title {
    @include text-body-4();
    font-weight: $fw_medium;
    color: $color_turquoise_dark;
  }

  .footer-brand {

    img {
      background-color: transparent;
      border-radius: 0;
      height: 3.2rem;
      vertical-align: middle;
    }

    .footer-brand-description {
      color: $color_turquoise_dark;
      margin-bottom: 0;
      @include text-body-4();
    }
  }

  .footer-access {
    justify-self: center;
  }

  .footer-legal {
    justify-self: end;
  }

  .footer-copyright {
    grid-column: span 3;
    text-align: center;
    @include text-body-4();
  }

  .footer-links-group a {
    @include text-body-4();
  }
}


/** Width media queries */

@media all and (max-width: 1024px)
{
  footer .footer-content {
    padding: $padding_xxl $padding_sm;
  }
}

@media all and (max-width: 768px)
{
  footer .footer-content {
    grid-template-columns: repeat(2, 1fr);

    .footer-brand {
      grid-column: span 2;
    }

    .footer-access {
      justify-self: start;
    }

    .footer-copyright {
      grid-column: span 2;
    }
  }
}

@media all and (max-width: 425px)
{
  footer .footer-content {
    display: flex;
    flex-direction: column;
    width: auto;

    .footer-brand,
    .footer-access,
    .footer-about {
      margin-bottom: $margin_l;
    }
  }
}
