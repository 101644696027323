@use "sass:map";

/** Select Button */

.free-select-button {
  border: $free-border-size-normal solid map.get($free-palette-neutral, 100);
  border-radius: $free-border-radius-s;
  display: flex;

  .free-select-button-option {
    border-right: $free-border-size-normal solid map.get($free-palette-neutral, 100);
    cursor: pointer;
    padding: 0.4rem 1.2rem;

    &:last-of-type {
      border-right: 0;
    }
  }
  .free-select-button-option-selected {
    background-color: map.get($free-palette-neutral, 100);
    cursor: default;
  }
}
