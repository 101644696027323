@use "sass:map";
@use "sass:math";

/** Form States Colors */

$free-form-color-default-input: map.get($free-palette-neutral, 200);
$free-form-color-default-selection: $free-color-primary-main;
$free-form-color-hover: map.get($free-palette-primary-main, 700);
$free-form-color-pressed: $free-color-outer-space;
$free-form-color-focused: map.get($free-palette-primary-main, 900);
$free-form-color-disabled: map.get($free-palette-neutral, 300);

/** Fieldset */

$free-fieldset-legend-color: map.get($free-palette-primary-main, 700);
$free-fieldset-legend-font-weight: $free-font-weight-medium;
$free-fieldset-legend-heading-level: 5;
$free-fieldset-legend-spacing: $free-margin-stack-s;

/** Form Labels & Texts */

$free-form-label-font-weight: $free-font-weight-medium;
$free-form-label-heading-level: 6;

$free-form-helper-text-body-size: 'small';
$free-form-helper-text-color: map.get($free-palette-neutral, 700);
$free-form-helper-text-spacing: $free-margin-stack-xxs;

$free-form-invalid-text-body-size: 'small';
$free-form-invalid-text-color: $free-color-error;
$free-form-invalid-text-spacing: $free-margin-stack-xxs;

/** Form Input: Generics (text, number, phone, etc) */

$free-text-input-height-s: 3.2rem;
$free-text-input-height-m: 4rem;
$free-text-input-height-l: 5.6rem;

$free-text-input-sizes: (
  "small": (
    "height": $free-text-input-height-s,
    "bodySize": "regular"
  ),
  "medium": (
    "height": $free-text-input-height-m,
    "bodySize": "regular"
  ),
  "large": (
    "height": $free-text-input-height-l,
    "bodySize": "large"
  ),
);

$free-text-input-background-color: $free-color-white;
$free-text-input-background-color-disabled: map.get($free-palette-neutral, 50);
$free-text-input-border-radius: $free-border-radius-m;
$free-text-input-border-size: $free-border-size-normal;
$free-text-input-color: $free-color-neutral;
$free-text-input-height-base: $free-text-input-height-m;
$free-text-input-label-spacing: $free-margin-stack-xs;
$free-text-input-padding-x: $free-padding-s;
$free-text-input-padding: 0 $free-text-input-padding-x;
$free-text-input-placeholder-color: map.get($free-palette-neutral, 500);
$free-text-input-icon-position: math.div($free-text-input-padding-x, 2);

@mixin free-text-input-box-icon($input-height, $icon-size, $side) {
  $padding-size: $free-text-input-padding-x + $icon-size;

  .free-icon {
    top: math.div($input-height - $icon-size, 2)
  }

  input {
    @if $side == leading {
      padding-left: $padding-size;
    } @else {
      padding-right: $padding-size;
    }
  }
}

/** Number Input */

$free-number-input-box-width-number: 12.8rem;
$free-number-input-box-width-price: 12.8rem;
$free-number-input-box-width-rate: 9.6rem;
$free-number-input-box-width-distance: 15.2rem;
$free-number-input-box-width-hours: 9.6rem;
$free-number-input-box-width-minutes: 12rem;

/** Textarea */

@mixin free-text-area-padding($free-text-input-height, $free-text-input-line-height) {
  $free-text-area-padding: math.div($free-text-input-height - $free-text-input-line-height, 2);
  padding-bottom: $free-text-area-padding;
  padding-top: $free-text-area-padding;
}

$free-text-area-num-rows: 5;

/** Date Input */

$free-date-input-label-spacing: $free-margin-stack-xs;
$free-date-input-spacing: $free-margin-inline-m;

/** Search Place Input */

@mixin free-search-place-input-suggestions-list-max-height($input-height) {
  max-height: ($free-search-place-input-num-suggestions + 0.5) * $input-height;
}

$free-search-place-input-height-s: $free-text-input-height-s;
$free-search-place-input-height-m: $free-text-input-height-m;
$free-search-place-input-height-l: $free-text-input-height-l;

$free-search-place-input-sizes: (
  "small": $free-search-place-input-height-s,
  "medium": $free-search-place-input-height-m,
  "large": $free-search-place-input-height-l
);

$free-search-place-input-height-base: $free-search-place-input-height-m;
$free-search-place-input-num-suggestions: 6;
$free-search-place-input-suggestion-item-background-color-hover: map.get($free-palette-neutral, 50);
$free-search-place-input-suggestion-item-background-color-pressed: map.get($free-palette-neutral, 100);
$free-search-place-input-suggestion-item-cursor: pointer;
$free-search-place-input-suggestion-item-padding: $free-text-input-padding;
$free-search-place-input-suggestion-item-separator-color: map.get($free-palette-neutral, 50);
$free-search-place-input-suggestions-list-background-color: $free-color-white;
$free-search-place-input-suggestions-list-border-radius: $free-border-radius-l;

/** Checkbox */

$free-checkbox-border-radius: $free-border-radius-s;
$free-checkbox-border-size: $free-border-size-normal;
$free-checkbox-check: transparent url(/assets/images/tick.svg) no-repeat center;
$free-checkbox-cursor-base: pointer;
$free-checkbox-size: 1.6rem;
$free-checkbox-spacing: $free-margin-inline-s;

/** Radio */

$free-radio-group-column-spacing: $free-margin-stack-xs;
$free-radio-group-row-spacing: $free-margin-stack-xxl;

$free-radio-border-radius: $free-border-radius-round;
$free-radio-border-size: $free-border-size-normal;
$free-radio-check-size: 1.2rem;
$free-radio-cursor-base: pointer;
$free-radio-label-spacing: $free-margin-stack-xs;
$free-radio-size: 1.6rem;
$free-radio-spacing: $free-margin-inline-s;
$free-radio-check-position: 1px;
