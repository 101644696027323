@use "sass:color";
@use "sass:map";

/** Gradients **/

$free-gradient-success: linear-gradient(
	to right,
	$free-color-white 0%,
	color.change($free-color-success, $alpha: 0.1) 100%
);

$free-gradient-error: linear-gradient(
	to right,
	$free-color-white 0%,
	color.change($free-color-error, $alpha: 0.1) 100%
);

$free-gradient-none: linear-gradient(
	to right,
	$free-color-white 0%,
	color.change($free-color-none, $alpha: 0.1) 100%
);

$free-gradient-cta-base: linear-gradient(
	180deg,
	map.get($free-palette-primary-cta, 300) 40%,
	map.get($free-palette-warning, 300) 99%,
  $free-color-warning 100%
);

$free-gradient-cta-hover: linear-gradient(
	180deg,
  $free-color-primary-cta 40%,
	map.get($free-palette-warning, 300) 99%,
  $free-color-warning 100%
);

