/** Snackbar */

#snackbar {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 50%;
  padding: $padding_sl $padding_sm;
  z-index: 1001;
  transform: translateX(-16rem);
  width: 32rem;

  &.clickable .snackbar-content {
    cursor: pointer;
  }

  .snackbar-content {
    flex-grow: 1;
    text-align: center;
    background-color: #134F5C;
    padding: $padding_sm $padding_s;
    border-radius: $br_infinite;
    box-shadow: $box_shadow_m;

    @each $color, $value in $theme_colors {
      &.#{$color} {
        background-color: nth($value, 1);
        color: nth($value, 2);

        a {
          color: nth($value, 2);
        }
      }
    }

    &.multiline {
      border-radius: $br_l;
      line-height: 1.5;
      text-align: left;
    }
  }
}
