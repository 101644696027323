/** Driving Licence Type Colors */

$train-driving-licence-color-b-manual: #A1F0F9;
$train-driving-licence-color-b-auto: #C9EFAB;
$train-driving-licence-color-b-accompanied: #85CDF7;
$train-driving-licence-color-bva-accompanied: #B4D799;
$train-driving-licence-color-a: #D5CBEB;
$train-driving-licence-color-c: #EDB0B0;
$train-driving-licence-color-d: #F4D3B4;
$train-driving-licence-color-other: #CCE8E5;
$train-driving-licence-color-boat: #20B2AA;
$train-driving-licence-color-pap: #FF69B4;
$train-driving-licence-color-test: #FFB6C1;

$train-driving-licence-type-colors: (
  'b-manual': $train-driving-licence-color-b-manual,
  'b-auto': $train-driving-licence-color-b-auto,
  'b-accompanied': $train-driving-licence-color-b-accompanied,
  'bva-accompanied': $train-driving-licence-color-bva-accompanied,
  'a': $train-driving-licence-color-a,
  'c': $train-driving-licence-color-c,
  'd': $train-driving-licence-color-d,
  'other': $train-driving-licence-color-other,
  'boat': $train-driving-licence-color-boat,
  'pap': $train-driving-licence-color-pap,
  'test': $train-driving-licence-color-test,
);


/** Driving Skills Colors */

$driving-skill-color-1: #66C8D2;
$driving-skill-color-2: #FF8585;
$driving-skill-color-3: #80D28E;
$driving-skill-color-4: #FEE066;
$driving-skill-color-5: #00A3B4;

$driving-skills-colors: (
  '1': $driving-skill-color-1,
  '2': $driving-skill-color-2,
  '3': $driving-skill-color-3,
  '4': $driving-skill-color-4,
  '5': $driving-skill-color-5
);
