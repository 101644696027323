/** Input Type Checkbox */

.free-checkbox {

  .free-checkbox-label {
    align-items: center;
    cursor: $free-checkbox-cursor-base;
    display: flex;
  }

  .free-form-invalid-text {
    margin-top: $free-form-invalid-text-spacing;
  }

  input[type="checkbox"] {
    -moz-appearance: none;
    height: 0;
    margin: 0;
    width: 0;

    &:checked + .free-checkbox-appearance {
      background-color: $free-form-color-default-selection;
      border: 0;

      &:after {
        transform: scale(1);
      }
    }

    &:hover  {

      & + .free-checkbox-appearance {
        border-color: $free-form-color-hover;
      }

      &:checked + .free-checkbox-appearance {
        background-color: $free-form-color-hover;
      }
    }

    &[disabled] {

      & + .free-checkbox-appearance {
        border-color: $free-form-color-disabled;
      }

      &:checked + .free-checkbox-appearance {
        background-color: $free-form-color-disabled;
      }
    }

    &:focus {

      & + .free-checkbox-appearance {
        border-color: $free-form-color-focused;
      }

      &:checked + .free-checkbox-appearance {
        background-color: $free-form-color-focused;
      }
    }

    &:active {

      & + .free-checkbox-appearance {
        border-color: $free-form-color-pressed;
      }

      &:checked + .free-checkbox-appearance {
        background-color: $free-form-color-pressed;
      }
    }
  }

  .free-checkbox-appearance {
    border-radius: $free-checkbox-border-radius;
    border: $free-checkbox-border-size solid $free-form-color-default-selection;
    cursor: $free-checkbox-cursor-base;
    min-height: $free-checkbox-size;
    max-height: $free-checkbox-size;
    margin: 0;
    position: relative;
    min-width: $free-checkbox-size;
    max-width: $free-checkbox-size;

    &:after {
      background: $free-checkbox-check;
      content: "";
      height: 100%;
      position: absolute;
      transform: scale(0);
      transition: 0.1s transform ease-in-out;
      width: 100%;
    }
  }

  .free-checkbox-label-text {
    padding-left: $free-margin-inline-s;
  }

  &.free-checkbox-disabled {
    cursor: default;

    .free-checkbox-label {
      cursor: default;
    }

    .free-checkbox-label-text {
      padding-left: 0;
    }
  }

  &.free-checkbox-invalid input[type="checkbox"] {

    & + .free-checkbox-appearance {
      border-color: $free-color-error;
    }
  }
}
