/** Panel */

$panel_bg_color:    $color_white;
$panel_br:          $br_l;
$panel_bs:          $box_shadow_m;
$panel_m_y:         $spacing_l;
$panel_padding_y:   $spacing_s;
$panel_padding_x:   $spacing_l;
$panel_padding:     $panel_padding_y $panel_padding_x;


/** Card */

$card_bg_color:     $color_white;
$card_br:           $br_l;
$card_header_fs:    $fs_m;
$card_header_fw:    $fw_normal;
$card_padding:      $spacing_m;
