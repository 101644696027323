/** Component: Planning Settings */

.planning-settings {
  position: relative;

  .free-box {
    background-color: $free-color-white;
  }

  .planning-settings-menu {
    position: absolute;
    right: 0;
  }

  .planning-settings-menu-content {
    display: flex;
    flex-direction: column;
    row-gap: $free-margin-stack-s;
  }

  .planning-settings-menu-actions {
    grid-column-gap: $free-margin-inline-m;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .planning-settings-hours {
    display: flex;
    column-gap: $free-margin-inline-m;
  }
}
