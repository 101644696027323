/** Period navigator */

nav.year-navigator,
nav.quarter-navigator,
nav.month-navigator,
nav.week-navigator,
nav.day-navigator {
  align-items: center;
  display: flex;

  .year-navigator-shortcut,
  .quarter-navigator-shortcut,
  .month-navigator-shortcut,
  .day-navigator-shortcut,
  .week-navigator-shortcut {
    border-radius: $br_infinite;
  }

  button,
  a,
  .navigation-link {
    color: $color_primary;
    margin-right: $spacing_l;

    &.btn {
      color:  $color_secondary;
      border: 1px solid $color_secondary;

      &.active {
        color:        $color_primary;
      }
    }

    .icon {
      font-size: $fs_l;
    }
  }

  .month,
  .day {
    white-space: nowrap;
  }

  .day-wrapper {
    position: relative;

    .day {
      cursor: pointer;
    }
  }

  .week-navigator-picker {
    position: absolute;
    top: 0;
    visibility: hidden;
  }
}

@media all and (max-width: 500px)
{
  nav.year-navigator,
  nav.quarter-navigator,
  nav.month-navigator,
  nav.week-navigator,
  nav.day-navigator {

    .year-navigator-shortcut,
    .quarter-navigator-shortcut,
    .month-navigator-shortcut,
    .day-navigator-shortcut,
    .week-navigator-shortcut {
      display: none;
    }
  }
}
