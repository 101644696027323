/** Margin **/

// Vertical Margin
$free-margin-stack-xxxs: 0.2rem;
$free-margin-stack-xxs: 0.4rem;
$free-margin-stack-xs: 0.8rem;
$free-margin-stack-s: 1.6rem;
$free-margin-stack-m: 2.4rem;
$free-margin-stack-l: 3.2rem;
$free-margin-stack-xl: 4.8rem;
$free-margin-stack-xxl: 6.4rem;
$free-margin-stack-xxxl: 8.0rem;
$free-margin-stack-4xl: 12.8rem;

// Horizontal Margin
$free-margin-inline-xxs: 0.2rem;
$free-margin-inline-xs: 0.4rem;
$free-margin-inline-s: 0.8rem;
$free-margin-inline-m: 1.6rem;
$free-margin-inline-l: 2.4rem;
$free-margin-inline-xl: 3.2rem;
$free-margin-inline-xxl: 4.8rem;
$free-margin-inline-xxxl: 6.4rem;
$free-margin-inline-4xl: 7.2rem;
$free-margin-inline-5xl: 9.6rem;
$free-margin-inline-6xl: 12.8rem;

/** Padding **/

// Base
$free-padding-xxs: 0.4rem;
$free-padding-xs: 0.8rem;
$free-padding-s: 1.6rem;
$free-padding-m: 2.4rem;
$free-padding-l: 3.2rem;
$free-padding-xl: 5.6rem;
$free-padding-xxl: 6.4rem;
$free-padding-xxxl: 12.8rem;
$free-padding-4xl: 16.8rem;

// Inset mode
$free-padding-inset-xxs: $free-padding-xxs;
$free-padding-inset-xs: $free-padding-xs;
$free-padding-inset-s: $free-padding-s;
$free-padding-inset-m: $free-padding-m;
$free-padding-inset-l: $free-padding-l;
$free-padding-inset-xl: $free-padding-xl;
$free-padding-inset-xxl: $free-padding-xxl;

// Squish mode
$free-padding-squish-s: $free-padding-xxs $free-padding-xs;
$free-padding-squish-m: $free-padding-xs $free-padding-s;
$free-padding-squish-l: $free-padding-s $free-padding-l;

// Stretch mode
$free-padding-stretch-s: $free-padding-s $free-padding-xs;
$free-padding-stretch-m: $free-padding-m $free-padding-s;

