/** Custom Breakpoint Mixin **/

@mixin free-breakpoint-custom($screen) {
  @media all and (max-width: #{$screen + 'px'}) {
    @content;
  }
}

/** Breakpoint Mixins **/

@mixin free-breakpoint-s {
  @media all and (max-width: #{$free-breakpoint-s}) {
    @content;
  }
}

@mixin free-breakpoint-m {
  @media all and (max-width: #{$free-breakpoint-m}) {
    @content;
  }
}

@mixin free-breakpoint-l {
  @media all and (max-width: #{$free-breakpoint-l}) {
    @content;
  }
}

@mixin free-breakpoint-xl {
  @media all and (max-width: #{$free-breakpoint-xl}) {
    @content;
  }
}
