@use "sass:math";

/** Dropdown toggle */

$dropdown_toggle_color: $color_blue_black;
$dropdown_toggle_fs: $fs_m;
$dropdown_toggle_fw: $fw_normal;
$dropdown_toggle_icon_color: $color_primary;
$dropdown_toggle_m: $margin_m;
$dropdown_toggle_py: $spacing_m;
$dropdown_toggle_px: $spacing_l;
$dropdown_toggle_p: $dropdown_toggle_py $dropdown_toggle_px;

$dropdown_tainted_title_fw: $fw_medium;
$dropdown_tainted_title_fs: $fs_s;
$dropdown_tainted_title_color: $color_primary;


/** Dropdown menu */

$dropdown_menu_bg_color: $color_white;
$dropdown_menu_br: $br_m;
$dropdown_menu_box_shadow: $box_shadow_m;


/** Dropdown item */

$dropdown_item_color: $color_blue_black;
$dropdown_item_p: $spacing_m;

$dropdown_item_hover_bg_color: $color_secondary;

$dropdown_item_selected_color: $color_primary;


/** Computed */

$dropdown_toggle_height: $dropdown_toggle_fs * $lh_base + ($dropdown_toggle_py * 2);
$dropdown_tainted_toggle_height: ($dropdown_tainted_title_fs + $dropdown_toggle_fs) * $lh_base;
$dropdown_tainted_toggle_p: math.div($dropdown_toggle_height - $dropdown_tainted_toggle_height, 2);
