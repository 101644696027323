/** Page: List of students */

$driving_lesson_status_w: 1.4rem;
$driving_lesson_date_w: 12.2rem;
$driving_lesson_duration_w: 5rem;
$driving_lesson_type_w: 17rem;

#pageStudentsList {
  padding-bottom: $padding_sl;

  .students-wrapper {
    margin: auto;
    padding: 0 $padding_sl;
    max-width: 140rem;
  }

  .students-search-form {
    max-width: 70rem;
  }

  .student-list-item {
    margin-bottom: $margin_sm;
  }

  .student-row {
    align-items: center;
    display: grid;
    column-gap: $margin_sm;
    grid-template-columns: minmax(15rem, 1fr) 15rem 25rem repeat(3, 16rem) 3.1rem;

    .student-name,
    .student-email {
      overflow: hidden;
      white-space: nowrap;
    }

    .student-actions button {
      font-size: $fs_l;
    }
  }

  .student-info {
    margin-top: $margin_xs;
  }

  .student-details {
    display: flex;
    margin-bottom: $margin_sl;

    .student-private-details {
      margin-right: $margin_sl;
    }

    .student-private-details,
    .student-training-follow {
      width: 50%;

      > div {
        display: flex;
        margin-bottom: $margin_s;
      }

      .student-private-details-label,
      .student-training-follow-label {
        min-width: 10rem;
        color: $color_label;
      }
    }

    .student-email {
      overflow: hidden;
      white-space: nowrap;
    }

    .student-address .student-private-details-value > div {
      margin-bottom: $margin_xs;
    }
  }

  .student-training-header {
    margin-bottom: $margin_sm;
  }

  .student-training-switch {

    span {
      font-style: normal;
    }

    button {
      &:first-of-type {
        margin-left: $margin_s;
        border-radius: $btn_br 0 0 $btn_br;
      }

      &:last-of-type {
        margin-right: $margin_s;
        border-radius: 0 $btn_br $btn_br 0;
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: $margin_m;
  }
}


@media all and (max-width: 1280px)
{
  #pageStudentsList {

    .student-row {
      grid-template-columns: minmax(15rem, 1fr) 11.5rem 20rem repeat(2, 10rem) 10rem 3.1rem;
    }
  }
}

@media all and (max-width: 1024px)
{
  #pageStudentsList {

    .student-row {
      grid-template-columns: minmax(15rem, 1fr) 11.5rem 30rem 3.1rem;

      .student-done,
      .student-coming,
      .student-evaluation-none,
      .student-evaluation {
        display: none;
      }
    }
  }
}

@media all and (max-width: 800px)
{
  #pageStudentsList {

    .student-row {
      grid-template-columns: minmax(15rem, 1fr) 11.5rem 20rem 3.1rem;
    }
  }
}

@media all and (max-width: 768px)
{
  #pageStudentsList {

    .students-wrapper {
      padding: 0 $padding_s;
    }

    .student-details {
      flex-direction: column;

      .student-private-details {
        margin-right: 0;
        margin-bottom: $margin_s;
      }

      .student-private-details,
      .student-training-follow {
        width: auto;
      }
    }
  }
}

@media all and (max-width: 700px)
{
  #pageStudentsList {

    .student-row {
      grid-template-columns: minmax(15rem, 1fr) 11.5rem 15rem 3.1rem;
    }
  }
}

@media all and (max-width: 650px)
{
  #pageStudentsList {

    .student-row {
      grid-template-columns: minmax(15rem, 1fr) 11.5rem 3.1rem;

      .student-email {
        display: none;
      }
    }
  }
}

@media all and (max-width: 475px)
{
  #pageStudentsList {

    .student-row {
      grid-template-columns: 1fr 11.5rem 3.1rem;
    }
  }
}

@media all and (max-width: 425px)
{
  #pageStudentsList {

    .student-row {
      grid-template-columns: 1fr 3.1rem;

      .student-phone {
        display: none;
      }
    }

    .student-details {

      .student-private-details,
      .student-training-follow {

        .student-private-details-label,
        .student-training-follow-label {
          min-width: 9rem;
        }
      }
    }
  }
}
