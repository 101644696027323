@use "sass:map";

$box-shadow: 0 0 0 1px $free-color-black;
$student-training-book-mock-exam-rating-size: 4rem;
$student-training-book-mock-exam-result-value-min-width: 14rem;
$nbr-of-ratings: 5;
$sub-total-nbr-of-ratings: 3;
$nbr-of-ratings-autonomy: 3;
$sub-total-nbr-of-autonomy-ratings: 2;
$free-text-input-box-multi-min-height: 8rem;

@mixin table-like($grip-gap: true) {
  @if($grip-gap) {
    grid-gap:1px;
  }

  & > div,
  & > label {
    box-shadow: $box-shadow;
    background-color: $free-color_white;
  }
}

.student-training-book-mock-exam-card-details-form {
  display: grid;
  row-gap: $free-margin-inline-m;

  .free-text-input .free-text-input-box-multi {
    height: auto;
    min-height: $free-text-input-box-multi-min-height;

    textarea {
      min-height: $free-text-input-box-multi-min-height;
    }
  }

  .student-training-book-mock-exam-notes-title {
    @include free-text-heading(6, $free-font-weight-medium);
    margin-bottom: $free-margin-stack-xs;
  }

  .student-training-book-mock-exam-notes p {
    white-space: pre-wrap;
  }
  
  .student-training-book-mock-exam-actions {
    align-items: center;
    display: flex;
    flex-direction: column;
    row-gap: $free-margin-stack-s;
  }

  @media all and (min-width: $free-breakpoint-m) {
    .student-training-book-mock-exam-actions {
      column-gap: $free-margin-inline-m;
      flex-direction: row;
    }
  }
}

.student-training-book-mock-exam {
  @include table-like();
  display: grid;
  text-align: center;
  border-radius: $free-border-radius-m;
  box-shadow: $box-shadow;
  overflow: hidden;

  &.student-training-book-mock-exam-result {
    border-radius: $free-border-radius-m;

    .student-training-book-mock-exam-header {
      min-height: $student-training-book-mock-exam-rating-size;

      .student-training-book-mock-exam-result-value {
        min-height: $student-training-book-mock-exam-rating-size;
        padding-inline: $free-padding-s;

        &.favorable {
          background-color: map.get($free-palette-success, 100);
        }
        &.unfavorable {
          background-color: map.get($free-palette-error, 100);
        }
      }
    }
  }

  .student-training-book-mock-exam-sub-total-ratings,
  .student-training-book-mock-exam-ratings {
    @include table-like(false);
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
  }

  .student-training-book-mock-exam-sub-total,
  .student-training-book-mock-exam-total {
    @include table-like(false);
    display: grid;
    grid-template-columns: 1fr min-content;
  }

  &.student-training-book-mock-exam-bonus .student-training-book-mock-exam-header {
    grid-template-columns: 1fr min-content;
  }

  .student-training-book-mock-exam-header-title {
    @include free-text-heading(5, $free-font-weight-medium);

    display: grid;
    place-items: center;
    padding-block: $free-padding-xs;
  }

  .student-training-book-mock-exam-total,
  .student-training-book-mock-exam-sub-total,
  .student-training-book-mock-exam-wrapper,
  .student-training-book-mock-exam-header,
  .student-training-book-mock-exam-control-category,
  .student-training-book-mock-exam-control {
    grid-column: span 2;
  }

  .student-training-book-mock-exam-control {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    text-align: initial;

    .student-training-book-mock-exam-control-title {
      padding-left: $free-padding-xs;
    }
  }

  .student-training-book-mock-exam-header {
    @include table-like(false);
    display: grid;
    grid-template-columns: 1fr;
  }

  &.student-training-book-mock-exam-result .student-training-book-mock-exam-header-title,
  .student-training-book-mock-exam-total .student-training-book-mock-exam-total-title,
  .student-training-book-mock-exam-sub-total .student-training-book-mock-exam-sub-total-title,
  .student-training-book-mock-exam-control-category {
    display: grid;
    place-content: center;
    font-weight: $free-font-weight-medium;

    min-height: $student-training-book-mock-exam-rating-size;
    background-color: map.get($free-palette-neutral, 50);
  }

  .student-training-book-mock-exam-result-value {
    display: grid;
    place-items: center;
    font-weight: $free-font-weight-bold;

    min-width: $student-training-book-mock-exam-result-value-min-width;
  }

  .student-training-book-mock-exam-rating {
    width: $student-training-book-mock-exam-rating-size;
    height: $student-training-book-mock-exam-rating-size;

    display: grid;
    place-items: center;
    font-weight: $free-font-weight-bold;
    cursor: pointer;

    &.rating-eliminatory {
      background-color: map.get($free-palette-error, 300);
    }

    &.rating-not-acquired {
      background-color: map.get($free-palette-warning, 500);
    }

    &.rating-working {
      background-color: map.get($free-palette-warning, 300);
    }

    &.rating-skill-variably-acquired {
      background-color: map.get($free-palette-primary-cta, 300);
    }

    &.rating-skill-acquired {
      background-color: map.get($free-palette-success, 500);
    }

    &.disabled {
      background-color: map.get($free-palette-neutral, 300);
    }

    &.disabled,
    &.readonly {
      cursor: default;
    }

    &.disabled-input {
      cursor: not-allowed;
    }

    .checked,
    input[type="radio"]:checked + .student-training-book-mock-exam-rating-radio-appearance,
    input[type="checkbox"]:checked + .student-training-book-mock-exam-rating-checkbox-appearance {
      width: $student-training-book-mock-exam-rating-size;
      height: $student-training-book-mock-exam-rating-size;
      background:
        linear-gradient(
          to bottom right,
          transparent calc(50% - 1px),
          $free-color-black calc(50% - 1px),
          $free-color-black calc(50% + 1px),
          transparent calc(50% + 1px)
        ),
        linear-gradient(
          to bottom left,
          transparent calc(50% - 1px),
          $free-color-black calc(50% - 1px),
          $free-color-black calc(50% + 1px),
          transparent calc(50% + 1px),
        );
    }
  }

  .student-training-book-mock-exam-ratings-wrapper {
    display: grid;
    @include table-like();
  }

  @media all and (min-width: $free-breakpoint-m) {
    .student-training-book-mock-exam-header-title {
      padding-block: initial;
    }

    .student-training-book-mock-exam-control,
    .student-training-book-mock-exam-header {
      grid-template-columns: 1fr min-content;

      .student-training-book-mock-exam-ratings {
        justify-content: normal;
      }
    }
  }
}
