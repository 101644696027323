/** Gradient Placeholder **/

%free-gradient-placeholder {
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

/** Gradient Mixins **/

@mixin free-gradient-success() {
  @extend %free-gradient-placeholder;
  background: $free-gradient-success;
}

@mixin free-gradient-error() {
  @extend %free-gradient-placeholder;
  background: $free-gradient-error;
}

@mixin free-gradient-neutral() {
  @extend %free-gradient-placeholder;
  background: $free-gradient-none;
}

@mixin free-gradient-cta() {
  @extend %free-gradient-placeholder;
  background: $free-gradient-cta-base;
}

@mixin free-gradient-cta-hover() {
  @extend %free-gradient-placeholder;
  background: $free-gradient-cta-hover;
}
