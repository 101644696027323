/** Input Type Radio */

.free-radio-group {

  .free-form-label {
    margin-bottom: $free-radio-label-spacing;
  }

  .free-form-helper-text,
  .free-form-invalid-text {
    margin-top: $free-form-invalid-text-spacing;
  }

  .free-radio-group-list {
    display: flex;
  }

  &.free-radio-group-column .free-radio-group-list {
    flex-direction: column;

    .free-radio:not(:last-of-type) {
      margin-bottom: 0;
    }
  }

  &.free-radio-group-row .free-radio-group-list {

    .free-radio:not(:last-of-type) {
      margin-right: $free-radio-group-row-spacing;
    }
  }

  .free-radio-label {
    align-items: center;
    cursor: $free-radio-cursor-base;
    display: flex;
  }

  input[type="radio"] {
    height: 0;
    margin: 0;
    width: 0;

    &:checked + .free-radio-appearance:after {
      transform: scale(1);
    }

    &:hover  {

      & + .free-radio-appearance {
        border-color: $free-form-color-hover;
      }

      &:checked + .free-radio-appearance:after {
        background-color: $free-form-color-hover;
      }
    }

    &[disabled] {

      & + .free-radio-appearance {
        border-color: $free-form-color-disabled;
      }

      &:checked + .free-radio-appearance:after {
        background-color: $free-form-color-disabled;
      }
    }

    &:focus {

      & + .free-radio-appearance {
        border-color: $free-form-color-focused;
      }

      &:checked + .free-radio-appearance:after {
        background-color: $free-form-color-focused;
      }
    }

    &:active {

      & + .free-radio-appearance {
        border-color: $free-form-color-pressed;
      }

      &:checked + .free-radio-appearance:after {
        background-color: $free-form-color-pressed;
      }
    }
  }

  .free-radio-appearance {
    border-radius: $free-radio-border-radius;
    border: $free-radio-border-size solid $free-form-color-default-selection;
    cursor: $free-radio-cursor-base;
    height: $free-radio-size;
    margin: 0;
    position: relative;
    width: $free-radio-size;

    &:after {
      background-color: $free-form-color-default-selection;
      border-radius: $free-radio-border-radius;
      content: "";
      height: $free-radio-check-size;
      left: $free-radio-check-position;
      position: absolute;
      top: $free-radio-check-position;
      transform: scale(0);
      transition: 0.1s transform ease-in-out;
      width: $free-radio-check-size;
    }
  }

  .free-radio-label-text {
    padding-left: $free-margin-inline-s;
  }

  &.free-radio-group-invalid input[type="radio"] {
    & + .free-radio-appearance {
      border-color: $free-color-error;
    }
  }
}
