/** Date navigation input */

.free-date-navigation-input {
  align-items: center;
  column-gap: $free-margin-inline-m;
  display: flex;
  position: relative;

  .free-date-navigation-input-month-name {
    @include free-text-heading(5);
  }

  .free-date-navigation-input-day-name {
    @include free-text-heading(6);
  }

  .free-date-navigation-input-controls {
    column-gap: $free-margin-inline-s;
    display: flex;
  }

  .free-date-navigation-input-datepicker {
    position: absolute;
    top: 0;
    visibility: hidden;
  }
}
