@use "sass:map";

/** Typeahead Select Input */

$free-typeahead-select-input-height: $free-text-input-height-m;
$free-typeahead-select-input-num-suggestions: 6;

$free-typeahead-select-input-suggestions-background-color: $free-color-white;
$free-typeahead-select-input-suggestions-border-radius: $free-border-radius-l;

$free-typeahead-select-input-suggestion-background-color-hover: map.get($free-palette-neutral, 50);
$free-typeahead-select-input-suggestion-background-color-pressed: map.get($free-palette-neutral, 100);
$free-typeahead-select-input-suggestion-cursor: pointer;
$free-typeahead-select-input-suggestion-padding: $free-text-input-padding;
$free-typeahead-select-input-suggestion-separator-color: map.get($free-palette-neutral, 50);

.free-typeahead-select-input {
  position: relative;

  .free-form-label {
    margin-bottom: $free-date-input-label-spacing;
  }

  .free-form-helper-text,
  .free-form-invalid-text {
    margin-top: $free-form-invalid-text-spacing;
  }

  .free-text-input {
    position: relative;
  }

  .free-typeahead-select-input-suggestions {
    background: $free-typeahead-select-input-suggestions-background-color;
    border-radius: $free-typeahead-select-input-suggestions-border-radius;
    box-shadow: $free-box-shadow-medium;
    left: 0;
    margin-top: $free-margin-stack-xs;
    max-height: ($free-typeahead-select-input-num-suggestions + 0.5) * $free-typeahead-select-input-height;
    overflow-y: auto;
    position: absolute;
    right: 0;
    z-index: 10;
  }

  .free-typeahead-select-input-suggestion-wrapper {
    cursor: $free-typeahead-select-input-suggestion-cursor;
    padding: $free-typeahead-select-input-suggestion-padding;

    &:not(:last-of-type) .free-typeahead-select-input-suggestion {
      border-bottom: $free-border-size-normal solid $free-typeahead-select-input-suggestion-separator-color;
    }

    &:hover {
      background-color: $free-typeahead-select-input-suggestion-background-color-hover;
    }
    &:active {
      background-color: $free-typeahead-select-input-suggestion-background-color-pressed;
    }
  }

  .free-typeahead-select-input-suggestion {
    align-items: center;
    display: flex;
    height: $free-typeahead-select-input-height;
  }

  .free-typeahead-select-input-suggestion-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
