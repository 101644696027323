/** Design System */

@import "freedom/freedom";
@import "freedom-teach/freedom-teach";

/** Pages **/

@import "pages/student-training-book";
@import "pages/student-training-scheduler";
@import "pages/auth-login";
@import "pages/auth-reset-password";
@import "pages/auth-change-password";
@import "pages/auth-confirmation";

/** Components */

@import "components/student-training-book-online-evaluation-card";
@import "components/student-training-book-evaluation-card-details";
@import "components/planning-settings";
@import "components/student-training-book-mock-exam-card";
@import "components/student-sheet-training-book-add-lesson-form";
