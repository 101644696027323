/** Page: Auth Login */

#pageAuthConfirmation {

  .auth-confirmation-wrapper {
    margin: 0 auto;
    max-width: $free-breakpoint-m;
    min-width: $free-breakpoint-s;
    padding: $free-padding-l $free-padding-s 0;
  }
}
