@use "sass:map";
@use "sass:color";

/** Box shadow */

$free-box-shadow-low:
	0px 1px 9px 0px color.change($free-color-black, $alpha: 0.1),
	0px 0px 4px 0px color.change($free-color-primary-main, $alpha: 0.25);

$free-box-shadow-medium:
	-1px 2px 6px 3px color.change($free-color-black, $alpha: 0.15);

$free-box-shadow-high:
	0px 0px 20px 3px color.change($free-color-black, $alpha: 0.05),
	11px -5px 8px -9px color.change($free-color-primary-main, $alpha: 0.1),
	0px 3px 7px 0px color.change($free-color-primary-main, $alpha: 0.1);

/** Drop Shadow */

$free-drop-shadow-low:
  drop-shadow(0px 1px 9px color.change($free-color-black, $alpha: 0.1))
  drop-shadow(0px 0px 4px color.change($free-color-primary-main, $alpha: 0.25));
