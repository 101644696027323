@use "sass:map";

/** Headings */

@for $i from 1 through $free-num-heading-levels {
  .free-text-heading-#{$i} {
    @include free-text-heading($i);
  }
}

/** Paragraph / Body */

@each $size, $value in $free-body-sizes {
  .free-text-body-#{$size} {
    @include free-text-body-size($size);
  }
}

/** Weights */

@each $weight, $value in $free-font-weights {
  .free-text-#{$weight} {
    @include free-text-weight($weight);
  }
}

/** Link */

a {
  color: $free-link-color;
  text-decoration: $free-link-text-decoration;

  &:hover {
    color: $free-link-color-hover;
  }
}

/** Inline text */

strong,
em,
span,
q {
  display: inline;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

