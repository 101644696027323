@use "sass:map";
@use "sass:color";

/** Base Colors **/

$free-color-black: #010505;
$free-color-cyan: #D1F4FF;
$free-color-cyber-yellow: #FFCC00;
$free-color-dark-green: #2CB442;
$free-color-outer-space: #0D2F36;
$free-color-pacific-blue: #00A3B4;
$free-color-pumpkin: #F5812A;
$free-color-red: #FF3333;
$free-color-white: #FDFFFF;

/**
  Color Palettes

  N.B:
    500 is the base color key
    But 900 is the base color key for outer space
**/

$free-palette-primary-main: (
  100: #66C8D2,
  300: #33B5C3,
  500: $free-color-pacific-blue,
  700: #008290,
  900: #00626C,
);

$free-palette-primary-cta: (
  100: #FEE066,
  300: #FED633,
  500: $free-color-cyber-yellow,
  700: #CBA300,
  900: #987A00,
);

$free-palette-secondary-main: (
  100: #E3F8FF,
  300: #DAF6FF,
  500: $free-color-cyan,
  700: #A7C3CC,
  900: #7D9299,
);

$free-palette-neutral: (
  10: #F9F9F9,
  50: #E7EAEB,
  100: #CFD5D7,
  200: #B6C1C3,
  300: #9EACAF,
  400: #86979B,
  500: #6E8286,
  600: #566D72,
  700: #3D595E,
  800: #25444A,
  900: $free-color-outer-space,
);

$free-palette-success: (
  100: #80D28E,
  300: #56C368,
  500: $free-color-dark-green,
  700: #239035,
  900: #1A6C28,
);

$free-palette-warning: (
  100: #F9B37F,
  300: #F79A55,
  500: $free-color-pumpkin,
  700: #C46722,
  900: #934d19,
);

$free-palette-error: (
  100: #FF8585,
  300: #FF5C5C,
  500: $free-color-red,
  700: #CC2929,
  900: #991F1F,
);

/** Theme color **/

$free-color-primary-main: $free-color-pacific-blue;
$free-color-primary-cta: $free-color-cyber-yellow;
$free-color-secondary: $free-color-cyan;
$free-color-neutral: $free-color-outer-space;
$free-color-success: $free-color-dark-green;
$free-color-warning: $free-color-pumpkin;
$free-color-error: $free-color-red;
$free-color-notification: $free-color-red;
$free-color-none: map.get($free-palette-neutral, 500);

/** Functional color **/

$free-color-deleted: map.get($free-palette-neutral, 50);
