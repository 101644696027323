@use "sass:map";

/** Pagination */

.free-pagination {
  display: flex;
  justify-content: center;

  button {
    @include free-text-heading(5, $free-font-weight-regular);
  }

  .free-btn-label {
    color: $free-color-neutral;
  }
}

.free-pagination-numbered {
  align-items: center;
  display: flex;

  .free-pagination-numbered-pages {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .free-pagination-numbered-page:not(:last-of-type) {
    margin-right: $free-margin-inline-s;
  }

  .free-pagination-numbered-page button.free-btn-secondary {
    @include free-text-body-size('regular');
    padding: 0;
    width: 4rem;
  }

  .free-pagination-numbered-page-active button.free-btn-secondary {
    border-color: $free-color-neutral;
    color: $free-color-neutral;
  }

  .free-pagination-numbered-separator {
    color: map.get($free-palette-primary-main, 700);
  }
}
