/** Fonts */

// Rubik
@font-face {
  font-family: Rubik;
  src: url(/assets/fonts/Rubik/Rubik-Bold.woff2) format('woff2'),
  url(/assets/fonts/Rubik/Rubik-Bold.woff) format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Rubik;
  src: url(/assets/fonts/Rubik/Rubik-Italic.woff2) format('woff2'),
  url(/assets/fonts/Rubik/Rubik-Italic.woff) format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Rubik;
  src: url(/assets/fonts/Rubik/Rubik-ExtraBoldItalic.woff2) format('woff2'),
  url(/assets/fonts/Rubik/Rubik-ExtraBoldItalic.woff) format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Rubik;
  src: url(/assets/fonts/Rubik/Rubik-BlackItalic.woff2) format('woff2'),
  url(/assets/fonts/Rubik/Rubik-BlackItalic.woff) format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Rubik;
  src: url(/assets/fonts/Rubik/Rubik-ExtraBold.woff2) format('woff2'),
  url(/assets/fonts/Rubik/Rubik-ExtraBold.woff) format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Rubik;
  src: url(/assets/fonts/Rubik/Rubik-Light.woff2) format('woff2'),
  url(/assets/fonts/Rubik/Rubik-Light.woff) format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Rubik;
  src: url(/assets/fonts/Rubik/Rubik-LightItalic.woff2) format('woff2'),
  url(/assets/fonts/Rubik/Rubik-LightItalic.woff) format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Rubik;
  src: url(/assets/fonts/Rubik/Rubik-BoldItalic.woff2) format('woff2'),
  url(/assets/fonts/Rubik/Rubik-BoldItalic.woff) format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Rubik;
  src: url(/assets/fonts/Rubik/Rubik-Black.woff2) format('woff2'),
  url(/assets/fonts/Rubik/Rubik-Black.woff) format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Rubik;
  src: url(/assets/fonts/Rubik/Rubik-Regular.woff2) format('woff2'),
  url(/assets/fonts/Rubik/Rubik-Regular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Rubik;
  src: url(/assets/fonts/Rubik/Rubik-SemiBold.woff2) format('woff2'),
  url(/assets/fonts/Rubik/Rubik-SemiBold.woff) format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Rubik;
  src: url(/assets/fonts/Rubik/Rubik-MediumItalic.woff2) format('woff2'),
  url(/assets/fonts/Rubik/Rubik-MediumItalic.woff) format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Rubik;
  src: url(/assets/fonts/Rubik/Rubik-Medium.woff2) format('woff2'),
  url(/assets/fonts/Rubik/Rubik-Medium.woff) format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Rubik;
  src: url(/assets/fonts/Rubik/Rubik-SemiBoldItalic.woff2) format('woff2'),
  url(/assets/fonts/Rubik/Rubik-SemiBoldItalic.woff) format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

/** Karla Font **/

@font-face {
  font-family: Karla;
  src: url(/assets/fonts/Karla/Karla-Italic.woff2) format('woff2'),
  url(/assets/fonts/Karla/Karla-Italic.woff) format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Karla;
  src: url(/assets/fonts/Karla/Karla-Bold.woff2) format('woff2'),
  url(/assets/fonts/Karla/Karla-Bold.woff) format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Karla;
  src: url(/assets/fonts/Karla/Karla-ExtraBold.woff2) format('woff2'),
  url(/assets/fonts/Karla/Karla-ExtraBold.woff) format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Karla;
  src: url(/assets/fonts/Karla/Karla-ExtraBoldItalic.woff2) format('woff2'),
  url(/assets/fonts/Karla/Karla-ExtraBoldItalic.woff) format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Karla;
  src: url(/assets/fonts/Karla/Karla-BoldItalic.woff2) format('woff2'),
  url(/assets/fonts/Karla/Karla-BoldItalic.woff) format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Karla;
  src: url(/assets/fonts/Karla/Karla-ExtraLightItalic.woff2) format('woff2'),
  url(/assets/fonts/Karla/Karla-ExtraLightItalic.woff) format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Karla;
  src: url(/assets/fonts/Karla/Karla-ExtraLight.woff2) format('woff2'),
  url(/assets/fonts/Karla/Karla-ExtraLight.woff) format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Karla;
  src: url(/assets/fonts/Karla/Karla-Italic.woff2) format('woff2'),
  url(/assets/fonts/Karla/Karla-Italic.woff) format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Karla;
  src: url(/assets/fonts/Karla/Karla-Medium.woff2) format('woff2'),
  url(/assets/fonts/Karla/Karla-Medium.woff) format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Karla;
  src: url(/assets/fonts/Karla/Karla-LightItalic.woff2) format('woff2'),
  url(/assets/fonts/Karla/Karla-LightItalic.woff) format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Karla;
  src: url(/assets/fonts/Karla/Karla-Light.woff2) format('woff2'),
  url(/assets/fonts/Karla/Karla-Light.woff) format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Karla;
  src: url(/assets/fonts/Karla/Karla-Regular.woff2) format('woff2'),
  url(/assets/fonts/Karla/Karla-Regular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Karla;
  src: url(/assets/fonts/Karla/Karla-MediumItalic.woff2) format('woff2'),
  url(/assets/fonts/Karla/Karla-MediumItalic.woff) format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Karla;
  src: url(/assets/fonts/Karla/Karla-SemiBoldItalic.woff2) format('woff2'),
  url(/assets/fonts/Karla/Karla-SemiBoldItalic.woff) format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Karla;
  src: url(/assets/fonts/Karla/Karla-SemiBold.woff2) format('woff2'),
  url(/assets/fonts/Karla/Karla-SemiBold.woff) format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
