/** Modal */

.modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;

  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .panel {
    margin-top: 0;
  }

  .modal-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $color_black;
    opacity: 0.3;
  }

  .modal-actions {
    display: flex;
    justify-content: flex-end;

    .cancel {
      margin-right: $margin_sm;
    }
  }
}
