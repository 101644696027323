@use "sass:map";

/** Buttons */

button, button.free-btn, a.free-btn {
  @include free-button-base();

  /** Size **/

  @each $size, $value in $free-button-sizes {
    &.free-btn-#{$size} {
      $heading-level: map.get($value, "headingLevel");
      @include free-text-heading($heading-level);


      $height: map.get($value, "height");
      height: $height;
      padding: map.get($value, "padding");

      &.free-btn-multiline {
        border-radius: $free-border-radius-l;
        flex-direction: column;
        height: $height * 2;
      }

      &.free-btn-icon {
        width: $height;
      }

      .free-icon {
        min-width: map.get($free-heading-line-heights, $heading-level);
      }
    }
  }

  /** Type */

  &.free-btn-primary {
    @include free-button-primary();
  }

  &.free-btn-secondary {
    @include free-button-secondary();
  }

  &.free-btn-tertiary {
    @include free-button-tertiary();
  }

  &.free-btn-cta {
    @include free-button-cta();
  }

  /** Volume */

  &.free-btn-silent {
    font-weight: $free-font-weight-regular;
  }

  &.free-btn-quiet {
    font-weight: $free-button-font-weight-base;
  }

  &.free-btn-screaming {
    font-weight: $free-font-weight-bold;
  }

  /** Clickable / Touchable area */

  &.free-btn-no-sides {
    padding-left: 0;
    padding-right: 0;
  }
}
