/** Icon */

.free-icon,
.free-icon * {
  color: inherit;
  font-size: inherit;
}

.free-icon {
  text-align: center;
}
