/** Form */

::placeholder {
  color: $placeholder_color;
}

.form-group-title {
  margin-bottom: $margin_s;
  font-weight: $fw_medium;
}

input[type="email"],
input[type="number"],
input[type="tel"],
input[type="text"],
input[type="password"],
select,
textarea {
  background-color: transparent;
  padding: $input_p_y $input_p_x;

  &:focus {
    outline: 0;
  }

  &[disabled] {
    background-color: $color_light_gray;
    cursor: not-allowed;
  }
}

/** Hack: padding breaks the arrow click on Firefox */
input[type="number"] {
  padding-right: 0;
  //margin-right: $input_p_x;
}

textarea {
  font-family: $ff_base;
  font-size: $fs_body;
  font-weight: $fw_base;
  line-height: $lh_bigger;
  resize: vertical;
}

.form-group {
  margin-bottom: $form_group_mb;

  @each $color, $value in $theme_colors {
    &.#{$color} {
      .form-label, .help-text, select {
        color: nth($value, 1);
      }

      input[type="email"],
      input[type="number"],
      input[type="tel"],
      input[type="text"],
      input[type="password"],
      input[type="checkbox"] + label:before,
      .input-group {
        border-color: nth($value, 1);
      }
    }
  }

  .form-label {
    margin-bottom: $label_mb;
  }

  input[type="checkbox"] {
    @include checkableInput($checkbox_br);
  }

  input[type="radio"] {
    @include checkableInput($radio_br);
  }

  .help-text {
    font-size: $form_group_help_text_fs;
    margin-top: $form_group_help_text_margin_top;
  }

  .checkbox-group:not(:last-of-type) {
    margin-bottom: $margin_xs;
  }

  label + .input-group {
    margin-top: $margin_xs;
  }
}

input[type="email"],
input[type="number"],
input[type="tel"],
input[type="text"],
input[type="password"] {
  border: $input_border_size solid $input_bc;
  border-radius: $input_br;
}

.input-group {
  align-items: center;
  background-color: $input_group_bg_color;
  border: $input_border_size solid $input_bc;
  border-radius: $input_br;
  display: flex;
  position: relative;

  .input-group-append,
  .input-group-prepend {
    color: $placeholder_color;
  }

  .input-group-prepend {
    padding-left: $input_p_x;
  }

  .input-group-append {
    padding-right: $input_p_x;
  }

  input,
  textarea,
  select {
    border: 0;
    flex: 1 1 auto;
  }

  select {
    @include select();
  }
}


/** Search place/student */

$typeahead_border_size: 1px;

.typeahead {
  position: relative;

  ul {
    background-color:   $color_white;
    border:             $typeahead_border_size solid $input_bc;
    border-radius:      $input_br;
    box-sizing:         border-box;
    color:              $color_dark;
    position:           absolute;
    text-align:         left;
    width:              100%;
    z-index:            2;
    margin-top: -$typeahead_border_size;
    box-shadow: $box_shadow_m;
  }

  li {
    cursor:         pointer;
    padding:        $spacing_s $spacing_m;
    overflow-x:     hidden;
    text-overflow:  ellipsis;
    white-space:    nowrap;
    margin-bottom: 0;

    &:hover {
      background-color: $input_bc;
    }

    &:first-of-type {
      border-radius: $input_br $input_br 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 $input_br $input_br;
    }

    &:first-of-type:last-of-type {
      border-radius: $input_br;
    }
  }
}

.search-student-input {

  .typeahead-suggestions li {
    display: flex;
    flex-direction: column;

    .search-student-suggestion-name {
      font-weight: $fw_medium;
    }

    .search-student-suggestion-agency,
    .search-student-suggestion-progression {
      color: $color_gray;
      font-size: $fs_ms;
      margin-top: $margin_xs;
    }

    .search-student-suggestion-debtor {
      color: $color-danger;
    }
  }
}


/** Day input */

.day-input {

  .input-group > div {
    display: flex;
  }
  input {
    flex-grow: 1;
  }

  .day-input-date,
  .day-input-month {
    width: 5rem;
  }

  .day-input-year {
    width: 6.2rem;
  }
}


/** Width media queries */

@media all and (max-width: 1024px)
{
  input[type="email"],
  input[type="number"],
  input[type="tel"],
  input[type="text"],
  input[type="password"],
  select,
  textarea {
    font-size: $fs_m;
  }

  .day-input {

    .day-input-date,
    .day-input-month {
      width: 5.1rem;
    }

    .day-input-year {
      width: 6.6rem;
    }
  }
}
