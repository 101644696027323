/** Structure */

$badge_br: $br_infinite;
$badge_p_x: $padding_sm;
$badge_p_y: $padding_xs;
$badge_p: $badge_p_y $badge_p_x;


/** Skin */

$badge_1_fs: 1.4rem;
$badge_1_fw: $fw_medium;
$badge_1_lh: 2.1rem;


/** Mixin */

@mixin badge() {
  display: inline-block;
  border-radius: $badge_br;
  padding: $badge_p;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
}

@mixin badge-1() {
  font-size: $badge_1_fs;
  font-weight: $badge_1_fw;
  line-height: $badge_1_lh;
}

@mixin badge-solid() {
  @each $color, $value in $theme_colors {
    &.#{$color} {
      background-color: nth($value, 1);
      color: nth($value, 2);
    }
  }
}
