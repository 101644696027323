@use "sass:map";

/** Base Structure */

$free-button-background-color-base: $free-color-none;
$free-button-border-base: $free-border-size-normal solid transparent;
$free-button-border-radius-base: $free-border-radius-round;
$free-button-color-base: $free-font-color-base;
$free-button-cursor-base: pointer;
$free-button-font-weight-base: $free-font-weight-regular;
$free-button-icon-spacing: $free-padding-xs;

/** Button Size */

$free-button-padding-s: 0 $free-padding-xs;
$free-button-padding-m: 0 $free-padding-s;
$free-button-padding-l: 0 $free-padding-l;

$free-button-height-s: 3.2rem;
$free-button-height-m: 4rem;
$free-button-height-l: 5.6rem;

$free-button-heading-level-s: 6;
$free-button-heading-level-m: 6;
$free-button-heading-level-l: 5;

$free-button-sizes: (
  "small": (
    "height": $free-button-height-s,
    "headingLevel": $free-button-heading-level-s,
    "padding": $free-button-padding-s
  ),
  "medium": (
    "height": $free-button-height-m,
    "headingLevel": $free-button-heading-level-m,
    "padding": $free-button-padding-m
  ),
  "large": (
    "height": $free-button-height-l,
    "headingLevel": $free-button-heading-level-l,
    "padding": $free-button-padding-l
  )
);

$free-button-heading-level-base: $free-button-heading-level-m;
$free-button-height-base: $free-button-height-m;
$free-button-padding-base: $free-button-padding-m;

/**
  Button Structure and Skin
*/

$free-button-transform-active: scale(0.98);
$free-button-transition-active: 0.15s transform;

$free-button-color-default: $free-color-primary-main;
$free-button-color-hover: map.get($free-palette-primary-main, 700);

$free-button-primary-background-color-default: $free-button-color-default;
$free-button-primary-background-color-hover: $free-button-color-hover;
$free-button-primary-color-default: $free-color-white;
$free-button-primary-shadow-focused: $free-box-shadow-low;

$free-button-secondary-background-color: $free-color-white;
$free-button-secondary-color-default: $free-button-color-default;
$free-button-secondary-color-hover: $free-button-color-hover;
$free-button-secondary-shadow-focused: $free-box-shadow-low;

$free-button-tertiary-background-color: transparent;
$free-button-tertiary-border: none;
$free-button-tertiary-color-default: $free-button-color-default;
$free-button-tertiary-color-hover: $free-button-color-hover;
$free-button-tertiary-color-pressed: $free-color-neutral;

$free-button-cta-background-color-default: $free-gradient-cta-base;
$free-button-cta-background-color-focused: $free-gradient-cta-base;
$free-button-cta-background-color-hover: $free-gradient-cta-hover;
$free-button-cta-color: $free-color-neutral;
$free-button-cta-shadow-default: $free-box-shadow-low;
$free-button-cta-shadow-focused: $free-box-shadow-medium;
