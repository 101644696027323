/** Structure */

.badge {
  @include badge();
}


/** Skin */

.badge {
  @include badge-solid();
}
