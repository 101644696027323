/** Box shadow */

$box_shadow_s: 0 1px 1px rgba(47, 46, 51, 0.25);
$box_shadow_m: 0 4px 8px -4px rgba(47, 46, 51, 0.25);


/** Planning box shadow */

$planning_slot_expanded_bs:
  0 6px 10px 0 rgb(0 0 0 / 14%),
  0 1px 18px 0 rgb(0 0 0 / 12%),
  0 3px 5px -1px rgb(0 0 0 / 20%)
;

$planning_slot_details_bs:
  0 24px 38px 3px rgb(0 0 0 / 14%),
  0 9px 46px 8px rgb(0 0 0 / 12%),
  0 11px 15px -7px rgb(0 0 0 / 20%)
;
