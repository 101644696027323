/** Global font size */

html {
  font-size: $fs_root;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
}


/** Global font style and color */

body {
  background-color: $color_bg;
  color: $color_body;

  & * {
    font-family: $ff_base;
    font-size: $fs_body;
    line-height: $lh_base;
  }
}


/** Layout */

main {
  padding-bottom: 3.2rem;
}
