/** Font size */

$fs_root: 62.5%;  // 10px
$fs_base: 1rem;

$fs_xxs:    $fs_base * 0.6;
$fs_xs:     $fs_base * 0.8;
$fs_ms:     $fs_base * 1.2;
$fs_s:      $fs_base * 1.4;
$fs_m:      $fs_base * 1.6;
$fs_l:      $fs_base * 2.0;
$fs_xl:     $fs_base * 2.6;
$fs_xxl:    $fs_base * 3.0;

$fs_body: $fs_s;


/** Font weight */

$fw_lighter:    lighter;
$fw_thin:       100;
$fw_light:      300;
$fw_normal:     400;
$fw_medium:     500;
$fw_bold:       700;
$fw_bolder:     bolder;

$fw_base:           $fw_normal;
$fw_important:      $fw_medium;
$fw_very_important: $fw_bold;


/** Font family */

$ff_rubik: Rubik;
$ff_others: "Helvetica Neue", Helvetica, Arial;

$ff_rubik_ss: $ff_rubik, $ff_others, sans-serif;
$ff_base: $ff_rubik_ss;


/** Line height */

$lh_base: 1;
$lh_bigger: 1.2;
$lh_biggest: 1.5;
