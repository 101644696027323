@use "sass:map";

/** Training Availabilities Calendar */

$training-availabilities-calendar-column-gap: $free-margin-stack-s;
$training-availabilities-calendar-column-width: 6.4rem;
$training-availabilities-calendar-row-gap: $free-margin-stack-xs;

$training-availabilities-calendar-slot-color-default: $free-color-secondary;
$training-availabilities-calendar-slot-color-hover: map.get($free-palette-primary-main, 100);
$training-availabilities-calendar-slot-color-selected: $free-color-primary-cta;
$training-availabilities-calendar-slot-color-text: $free-color-neutral;

.student-training-scheduler-trainer-calendar {
  display: flex;
  margin-bottom: $free-margin-stack-s;

  .student-training-scheduler-trainer-calendar-days {
    display: flex;
    margin-bottom: $free-margin-stack-s;
  }

  .student-training-scheduler-trainer-calendar-day {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: $training-availabilities-calendar-column-width;

    &:not(:last-of-type) {
      margin-right: $training-availabilities-calendar-column-gap;
    }
  }

  .student-training-scheduler-trainer-calendar-day-name {
    @include free-text-heading(6, $free-font-weight-medium);
  }

  .student-training-scheduler-trainer-calendar-day-date {
    @include free-text-heading(6);
  }

  .student-training-scheduler-trainer-calendar-slots {
    display: inline-flex;
    flex-direction: column;

    &:not(:last-of-type) {
      margin-right: $training-availabilities-calendar-column-gap;
    }

    button {
      font-weight: $free-font-weight-regular;
      width: $training-availabilities-calendar-column-width;

      &:not(.free-btn-tertiary) {
        @include free-button-primary-colors(
          $training-availabilities-calendar-slot-color-default,
            $training-availabilities-calendar-slot-color-hover,
            $training-availabilities-calendar-slot-color-text
        );
      }
    }

    .student-training-scheduler-trainer-calendar-slot-not-bookable button {
      @include free-button-primary-colors(
          map.get($free-palette-neutral, 100),
          map.get($free-palette-neutral, 100),
          $free-color-neutral
      );
    }

    .student-training-scheduler-trainer-calendar-slot-selected button {
      @include free-button-primary-colors(
        $training-availabilities-calendar-slot-color-selected,
          $training-availabilities-calendar-slot-color-selected,
          $training-availabilities-calendar-slot-color-text
      );
    }
  }

  .student-training-scheduler-trainer-calendar-slot {
    margin-bottom: $training-availabilities-calendar-row-gap;
  }
}
