@use "sass:map";

/** Dropdown */

@mixin free-dropdown-list-max-height($list-box-height) {
  max-height: ($free-dropdown-list-box-num-items + 0.5) * $list-box-height;
}

.free-dropdown {

  .free-form-label {
    margin-bottom: $free-dropdown-label-spacing;
  }

  .free-form-helper-text,
  .free-form-invalid-text {
    margin-top: $free-form-invalid-text-spacing;
  }

  @each $size, $value in $free-dropdown-sizes {
    &.free-dropdown-#{$size} {

      .free-dropdown-list-item {
        height: $value;
      }

      .free-dropdown-list-item-sub-labeled {
        height: $value + $free-padding-s;
      }

      .free-dropdown-list-item-separator {
        border-top: solid 1px #D9D9D9;
      }

      .free-dropdown-list {
        @include free-dropdown-list-max-height($value);
      }
    }
  }

  .free-dropdown-list-box {
    position: relative;
    width: 100%;
  }

  .free-dropdown-list-box-field {
    width: 100%;

    &.free-dropdown-list-box-field-high {
      box-shadow: $free-box-shadow-medium;
    }

    &:not(.free-btn-icon) {
      background: $free-dropdown-list-box-field-background-color;
      justify-content: space-between;
      padding: $free-dropdown-list-box-padding;
    }
    &.free-btn-icon {
      justify-content: center;
    }

    &.free-dropdown-list-box-field-select {
      border: $free-dropdown-list-box-field-select-border-size solid $free-form-color-default-input;
      border-radius: $free-dropdown-list-box-field-select-border-radius;

      .free-dropdown-list-box-placeholder {
        color: $free-dropdown-list-box-field-select-placeholder-color;
      }

      &:focus {
        border-color: $free-form-color-focused;
      }

      &:hover  {
        border-color: $free-form-color-hover;
      }

      &[disabled] {
        border-color: $free-form-color-disabled;
      }

      &:active {
        border-color: $free-form-color-pressed;
      }
    }

    .free-dropdown-list-box-menu-icon {
      color: $free-dropdown-list-box-menu-icon;
    }
  }

  &.free-dropdown-invalid .free-dropdown-list-box-field.free-dropdown-list-box-field-select {
    border-color: $free-color-error;
  }

  .free-dropdown-list-box-label {
    @include free-text-body-base();
    font-weight: $free-dropdown-list-box-label-font-weight;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .free-dropdown-list-box-placeholder {
    @include free-text-heading($free-dropdown-list-box-placeholder-heading-level);
  }

  .free-dropdown-list {
    @include free-dropdown-list-max-height($free-dropdown-list-box-height-base);
    background: $free-dropdown-list-background-color;
    border-radius: $free-dropdown-list-border-radius;
    box-shadow: $free-box-shadow-medium;
    //margin-top: $free-margin-stack-xs;
    min-width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    z-index: 11;

    &.free-dropdown-list-left {
      right: 0;
    }

    .free-checkbox,
    .free-dropdown-list-item-single-label,
    .free-dropdown-list-item-single-sub-label {
      white-space: nowrap;
    }

    .free-dropdown-list-item-single-sub-label {
      @include free-text-body-size('small', 'regular');
      color: map.get($free-palette-neutral, 800);
    }
  }

  .free-dropdown-list-item {
    align-items: center;
    cursor: $free-dropdown-list-item-cursor;
    display: flex;
    height: $free-dropdown-list-box-height-base;
    padding: $free-dropdown-list-box-padding;

    &.free-dropdown-list-item-disabled {
      cursor: default;
    }

    &:not(.free-dropdown-list-item-disabled):hover {
      background-color: $free-dropdown-list-item-background-color-hover;
    }

    &:not(.free-dropdown-list-item-disabled):active {
      background-color: $free-dropdown-list-item-background-color-pressed;
    }

    &.free-dropdown-list-item-selected {
      background-color: $free-dropdown-list-item-background-color-selected;
    }
  }
}
