@use "sass:map";

/** Font Family **/

$free-font-family-rubik: Rubik;
$free-font-family-karla: Karla;
$free-font-family-fallback: "Helvetica Neue", Helvetica, Arial;

$free-font-family-rubik-ss: $free-font-family-rubik, $free-font-family-fallback, sans-serif;
$free-font-family-karla-ss: $free-font-family-karla, $free-font-family-fallback, sans-serif;

$free-font-family-heading: $free-font-family-rubik-ss;
$free-font-family-body: $free-font-family-karla-ss;

/** Base Font color **/

$free-font-color-base: $free-color-outer-space;

/** Font weights **/

$free-font-weight-regular: 400;
$free-font-weight-medium: 500;
$free-font-weight-bold: 700;

$free-font-weights: (
	"regular": $free-font-weight-regular,
	"medium": $free-font-weight-medium,
	"bold": $free-font-weight-bold,
);

/** Heading **/

$free-num-heading-levels: 6;

$free-heading-font-sizes: (
	1: 4.9rem,
	2: 3.9rem,
	3: 3.1rem,
	4: 2.5rem,
	5: 2.0rem,
	6: 1.6rem,
  7: 1.2rem,
);

$free-heading-line-heights: (
	1: 6.4rem,
	2: 4.8rem,
	3: 4rem,
	4: 3.2rem,
	5: 2.4rem,
	6: 2.4rem,
);

/** Body **/

$free-body-sizes: "small", "regular", "large";

$free-body-font-size-small: 1.2rem;
$free-body-font-size-regular: 1.6rem;
$free-body-font-size-large: 2rem;

$free-body-line-height-small: 1.6rem;
$free-body-line-height-regular: 2.4rem;
$free-body-line-height-large: 2.4rem;

$free-body-font-sizes: (
  "small": $free-body-font-size-small,
  "regular": $free-body-font-size-regular,
  "large": $free-body-font-size-large
);

$free-body-line-heights: (
  "small": $free-body-line-height-small,
  "regular": $free-body-line-height-regular,
  "large": $free-body-line-height-large
);

/** Link */

$free-link-color: $free-color-primary-main;
$free-link-color-hover: map.get($free-palette-primary-main, 700);
$free-link-text-decoration: none;
