/** Confirm Action */

.free-confirm-action {
  position: relative;

  .free-confirm-action-box {
    background-color: $free-confirm-action-box-background-color;
    border: $free-confirm-action-box-border-size solid $free-confirm-action-box-border-color;
    border-radius: $free-confirm-action-box-border-radius;
    padding: $free-confirm-action-box-padding;
    position: absolute;
    width: $free-confirm-action-box-width;
    z-index: 10;

    &.free-confirm-action-box-left {
      right: 0;
    }

    &.free-confirm-action-box-bottom {
      margin-top: $free-confirm-action-box-spacing;
    }

    &.free-confirm-action-box-top {
      bottom: 100%;
      margin-bottom: $free-confirm-action-box-spacing;
    }
  }

  .free-confirm-action-text {
    @include free-text-body-size('regular');
    margin-bottom: $free-margin-stack-m;
  }

  .free-confirm-action-actions {
    display: flex;
    justify-content: flex-end;
  }

  .free-confirm-action-cancel {
    margin-right: $free-margin-inline-m;
  }
}
