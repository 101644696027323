@use "sass:math";

/** Field group */

$form_group_help_text_fs:           $fs_s;
$form_group_help_text_margin_top:   $spacing_xs;
$form_group_mb:                     $spacing_m;


/** Input group */

$input_group_bg_color:              $color_white;


/** Label */

$label_mb: $spacing_xs;


/** Input */

$input_bc: $color_ice;
$input_br: $br_l;
$input_border_size: 1px;
$input_border_size_in_rem: 0.1rem;
$input_p_x: $spacing_s;
$input_p_y: $spacing_base;

$input_focus_bc: $color_primary;


/** Placeholder */

$placeholder_color: $color_dark_gray;


/** Checkbox / Radio (=checkable) */

$checkbox_br: $br_s;
$radio_br: $br_infinite;

$checkable_bg_color: $color_white;
$checkable_border: $input_border_size solid $color_ice;
$checkable_size: $fs_m;

$checkable_checked_color: $color_glacier_bleu;

$checkable_checked_size: $checkable_size * 0.75;
$checkable_checked_position: math.div($checkable_size - $checkable_checked_size, 2) + $input_border_size_in_rem;

@mixin checkableInput($checkable_br: $checkbox_br) {
  display: none;

  & + label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    &:before {
      align-self: flex-start;
      background-color: $checkable_bg_color;
      border: $checkable_border;
      border-radius: $checkable_br;
      content: '';
      height: $checkable_size;
      margin-right: $margin_s;
      min-width: $checkable_size;
      width: $checkable_size;
    }

    &:hover:before {
      border-color: $checkable_checked_color;
    }
  }

  &:checked + label:after {
    align-self: flex-start;
    background-color: $checkable_checked_color;
    border: 0;
    border-radius: $checkable_br;
    content: '';
    height: $checkable_checked_size;
    min-width: $checkable_checked_size;
    width: $checkable_checked_size;
    position: absolute;
    left: $checkable_checked_position;
    top: $checkable_checked_position;
  }

  &[disabled] + label {
    cursor: not-allowed;

    &:hover:before {
      border-color: $color_ice;
    }
  }

  &[disabled] + label:before,
  &[disabled]:checked + label:after {
    border-color: $color_disabled !important;
  }
}


/** Select */

$select_bg: transparent url(/assets/images/chevron_down_solid_glacier_bleu.svg) no-repeat right;

@mixin select() {
  appearance: none;
  background: $select_bg;
  background-size: $fs_s;
  margin-right: $spacing_base;
  padding-right: $spacing_base * 2;

  option {
    color: $color_body;
  }

  option.placeholder,
  &.untainted {
    color: $color_none;
  }

}


/** Editable field */

@mixin editableField($minHeight: 2.5rem, $py: $spacing_xxs, $align: center, $maxWidth: 100%) {
  display: flex;
  align-items: $align;
  min-height: $minHeight;
  max-width: $maxWidth;

  .form-group {
    flex-grow: 1;
    margin-bottom: 0;

    input[type="number"],
    input[type="text"],
    input[type="email"],
    select,
    textarea {
      min-width: 0;
      padding: $py $input_p_x;
      width: 0;
    }
  }

  > button {
    background-color: transparent;
    color: $color_dark;
    padding: $py 0;
    text-align: left;
    text-transform: none;
    white-space: normal;

    &.none {
      background-color: transparent;
      color: $color_none;
    }
  }
}
