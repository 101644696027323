/** Header / Navbar */

#navigationBar {
  align-items: center;
  background-color: $navbar_bg_color;
  display: flex;
  padding: $navbar_p;
  position: relative;
  z-index: 2;

  .nav-spacer {
    flex-grow: 1;
  }

  .navbar-brand {
    display: flex;
    align-items: center;

    img {
      height: $navbar_brand_h;
    }

    img.navbar-brand-square {
      display: none;
    }
  }

  .nav-link {
    color: $navbar_fc;
    padding: $navlink_p;
    white-space: nowrap;
    @include btn-1();

    &:not(:last-of-type) {
      margin-right: $navlink_spacing;
    }

    &.active {
      @include btn();
      background-color: $navlink_active_bg_color;
      color: $navlink_active_fc;
    }
  }

  .menu-toggle {
    display: none;
  }

  .menu-content {
    align-items: center;
    display: flex;

    .nav-login {
      display: none;
    }
  }

  .user-widget {
    background-color: $color_primary;
    color: $color_white;
    margin-left: $margin_l;

    .free-dropdown-list {
      max-height: none;
      color: $color_blue_black;
    }

    .free-dropdown-list-box-field {
      background-color: $color_primary;
      color: $color_white;
      padding: 0;
    }

    .free-dropdown-list-box-menu-icon {
      color: $color_white;
    }

    .driving-school-name {
      color: $navbar_fc;
      margin-right: 0;
      @include btn-2();
    }

    .free-dropdown-list-item-disabled {
      border-top: solid 1px #86979B;
      color: #86979B;
    }
  }
}


/** Media queries */

@media all and (max-width: 1024px)
{
  #navigationBar {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: $navbar_p_y $navbar_p_x_s;

    &.navbar-light {
      .navbar-brand {
        margin: 0;
      }

      .navbar-marketing {
        display: none;
      }
    }

    .navbar-brand {
      grid-column: 1 / 3;
      grid-row: 1;
      justify-self: center;
    }

    .nav-user-widget {
      grid-column: 2;
      grid-row: 1;
      justify-self: end;
    }

    .menu {
      grid-column: 1;
      grid-row: 1;
      justify-self: start;
      position: relative;

      .menu-toggle {
        display: block;
      }

      .menu-content {
        display: none;
      }

      &.collapsed {

        .menu-content {
          align-items: flex-start;
          background-color: $color_turquoise_dark;
          box-shadow: $box_shadow_m;
          display: flex;
          flex-direction: column;
          height: 100%;
          left: 0;
          min-width: 27rem;
          position: fixed;
          top: 0;
          z-index: 3;

          .nav-link {
            border: 0;
            text-align: left;
          }

          .nav-link {
            border-radius: 0;
            box-sizing: border-box;
            font-size: $navlink_fs;
            margin: 0;
            padding: $navlink_p_y_burger $navbar_p_x_s;
            width: 100%;

            & * {
              font-size: $navlink_fs;
            }
          }

          .nav-login {
            display: block;
          }
        }
      }
    }
  }
}

@media all and (max-width: 600px)
{
  #navigationBar {

    .dropdown-label {
      display: none;
    }

    .navbar-brand img {

      &.navbar-brand-h {
        display: none;
      }

      &.navbar-brand-square {
        display: block;
      }
    }
  }
}

@media all and (max-width: 600px)
{
  #navigationBar {

    .navbar-brand img {

      &.navbar-brand-h {
        display: none;
      }

      &.navbar-brand-square {
        display: block;
      }
    }
  }
}
