@use "sass:math";

/** Navigation menu */

.page-header {
  background-color: $color_white;
  padding: $padding_sl 0 0 0;
  margin-bottom: $margin_sl;
  box-shadow: $box_shadow_m;

  &.page-header-no-nav {
    padding: $padding_sl 0;
  }

  nav {

    ul {
      display: flex;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
      }

      a {
        color: $color_blue_black;
        font-size: $fs_m;
        display: inline-block;
        padding: $padding_sm $padding_sl;
        text-align: center;
        flex-grow: 1;

        &.active {
          color: $color_black;
          border-bottom: 2px solid $color_black;
        }
      }
    }
  }
}


/** Mixin */

@mixin layout-navmenu($numItems, $maxWidth: 475px) {

  @media all and (max-width: $maxWidth)
  {
    .page-header nav ul li {
      width: math.div(100%, $numItems);

      a {
        font-size: $fs_s;
        padding: $padding_s $padding_xs;
      }
    }
  }
}
