@use 'sass:map';

/** Text Input */

.free-text-input {

  .free-form-label {
    margin-bottom: $free-text-input-label-spacing;
  }

  .free-text-input-box {
    height: $free-text-input-height-base;
    position: relative;
    z-index: 1;

    &.free-text-input-box-high {
      input, textarea {
        border: 0;
        box-shadow: $free-box-shadow-medium;
      }
    }

    &.free-text-input-box-rounded {
      input, textarea {
        border-radius: $free-border-radius-round;
      }
    }
  }

  .free-text-input-box-multi {
    height: $free-text-input-height-base * $free-text-area-num-rows;
  }

  .free-text-input-box-leading-icon {
    @include free-text-input-box-icon($free-text-input-height-base, $free-body-line-height-regular, leading);
  }

  .free-text-input-box-trailing-icon {
    @include free-text-input-box-icon($free-text-input-height-base, $free-body-line-height-regular, trailing);
  }
  .free-text-input-box-trailing-text {
    display: flex;

    input {
      border-radius: $free-text-input-border-radius 0 0 $free-text-input-border-radius;
    }

    .free-text-input-trailing-text {
      align-items: center;
      background-color: map.get($free-palette-neutral, 50);
      border-radius: 0 $free-text-input-border-radius $free-text-input-border-radius 0;
      display: flex;
      padding: $free-text-input-padding;
    }
  }

  @each $size, $value in $free-text-input-sizes {
    &.free-text-input-#{$size} {
      $free-text-input-body-size: map.get($value, "bodySize");
      $free-text-input-height: map.get($value, "height");
      $free-text-input-line-height: map.get($free-body-line-heights, $free-text-input-body-size);

      .free-text-input-box {
        height: $free-text-input-height;
      }

      .free-text-input-box-multi {
        height: $free-text-input-height * $free-text-area-num-rows;
      }

      input,
      textarea {
        @include free-text-body-size($free-text-input-body-size);

        &::placeholder {
          @include free-text-body-size($free-text-input-body-size);
        }
      }

      textarea {
        @include free-text-area-padding($free-text-input-height, $free-text-input-line-height);
      }

      .free-text-input-box-leading-icon {
        @include free-text-input-box-icon($free-text-input-height, $free-text-input-line-height, 'left');
      }

      .free-text-input-box-trailing-icon {
        @include free-text-input-box-icon($free-text-input-height, $free-text-input-line-height, 'right');
      }

      .free-icon {
        min-width: map.get($free-body-line-heights, $free-text-input-body-size);
        text-align: center;
      }
    }
  }

  .free-icon {
    z-index: 3;
  }

  .free-text-input-leading-icon {
    position: absolute;
    left: $free-text-input-icon-position;
  }

  input,
  textarea {
    @include free-text-body-base();
    background-color: $free-text-input-background-color;
    border: $free-text-input-border-size solid $free-form-color-default-input;
    border-radius: $free-text-input-border-radius;
    color: $free-text-input-color;
    height: 100%;
    padding: $free-text-input-padding;
    position: relative;
    width: 100%;
    z-index: 2;

    &::placeholder {
      @include free-text-body-base();
      color: $free-text-input-placeholder-color;
    }

    &:focus {
      border-color: $free-form-color-focused;
    }

    &:hover  {
      border-color: $free-form-color-hover;
    }

    &[disabled],
    &[disabled]:hover {
      background-color: $free-text-input-background-color-disabled;
      border-color: $free-form-color-disabled;
      cursor: not-allowed;
    }

    &:active {
      border-color: $free-form-color-pressed;
    }
  }

  textarea {
    @include free-text-area-padding($free-text-input-height-base, $free-body-line-height-regular);
  }

  .free-text-input-box-flat {

    input,
    textarea {
      border: $free-text-input-border-size solid $free-form-color-default-input;
    }
  }

  .free-text-input-box-medium {

    input,
    textarea {
      box-shadow: $free-box-shadow-medium;
    }
  }

  .free-text-input-trailing-icon {
    position: absolute;
    right: $free-text-input-icon-position;
  }

  &.free-text-input-invalid {

    input,
    textarea {
      border-color: $free-color-error;
    }
  }

  .free-form-helper-text,
  .free-form-invalid-text {
    margin-top: $free-form-invalid-text-spacing;
  }
}
