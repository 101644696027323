/** Page: Auth Reset Password */

#pageAuthResetPassword {

  .auth-reset-password-wrapper {
    margin: 0 auto;
    max-width: $free-breakpoint-m;
    min-width: $free-breakpoint-s;
    padding: 0 $free-padding-s;
  }

  .auth-reset-password-header {
    margin-bottom: $free-margin-stack-m;
    padding-top: $free-padding-l;

    h1 {
      @include free-text-heading(4, $free-font-weight-medium);
      margin-bottom: $free-margin-stack-l;
    }
  }

  fieldset {
    display: grid;
    grid-row-gap: $free-margin-stack-s;
  }

  .auth-reset-password-form-submit {
    margin-top: $free-margin-stack-l;
  }
}
