@use 'sass:map';
@use 'sass:list';
@use "sass:math";
@use "sass:color";

/** Card Training Book Driving Lesson */

$training-book-driving-lesson-date-width: 10.9rem;
$training-book-driving-lesson-time-width-s: 8.8rem;
$training-book-driving-lesson-time-width-l: 4.8rem;
$training-book-driving-lesson-trainer-width: 16rem;

$training-book-driving-lesson-card-separator-size: 4px;
$training-book-driving-lesson-card-separator-color: map.get($free-palette-neutral, 50);

$driving-lesson-skill-color-not-worked: map.get($free-palette-neutral, 100);
$driving-lesson-skill-color-worked: map.get($free-palette-primary-cta, 100);
$driving-lesson-skill-color-validated: map.get($free-palette-success, 100);

$driving-lesson-global-skill-number-size: 4rem;
$driving-lesson-sub-skill-number-size: 3.2rem;

$driving-lesson-schema-image-height: 20.0rem;
$driving-lesson-schema-image-aspect-ratio: list.slash(8, 13); // 8 / 13 (prevent sass division)
$driving-lesson-schema-image-width: calc(#{$driving-lesson-schema-image-height} * #{$driving-lesson-schema-image-aspect-ratio});
$driving-lesson-schema-enlarged-max-width: 32rem;

$driving-lesson-schema-delete-size: 3.2rem;
$driving-lesson-schema-delete-offset: calc(0px - #{$driving-lesson-schema-delete-size} + 8px);

$student-lesson-schema-type-figure-height: 16.4rem;
$student-lesson-schema-type-figure-width: calc(#{$student-lesson-schema-type-figure-height} * #{$driving-lesson-schema-image-aspect-ratio});

.student-training-book-driving-lesson-card {

  &.driving-lesson-cancelled .free-box {
    background-color: map.get($free-palette-neutral, 50);
  }

  &.driving-lesson-failed {

    .free-box {
      box-shadow: 0 0 4px 0 color.change(map.get($free-palette-error, 700), $alpha: 0.4);
    }

    .student-training-book-driving-lesson-card-main {
      grid-template-areas:
        "lessonType lessonType actions"
        "trainer trainer actions"
        "failed failed failed"
        "lessonDate lessonDate lessonDate"
        "lessonTime lessonDuration .";
    }
  }

  .student-training-book-driving-lesson-card-main {
    column-gap: 3.2rem;
    display: grid;
    grid-template-areas:
      "lessonType lessonType actions"
      "trainer trainer actions"
      "skills skills skills"
      "signatures signatures signatures"
      "lessonDate lessonDate lessonDate"
      "lessonTime lessonDuration ."
      "toggles toggles toggles";
    grid-template-columns: auto 1fr 4rem;

    .driving-lesson-type {
      @include free-text-heading(6, $free-font-weight-medium);
      grid-area: lessonType;
    }
    .student-training-book-driving-lesson-card-actions {
      grid-area: actions;
    }
    .driving-lesson-trainer {
      @include free-text-heading(6);
      grid-area: trainer;
      padding-bottom: $free-padding-xs;
    }
    .driving-lesson-worked-skills {
      column-gap: $free-margin-inline-m;
      display: flex;
      grid-area: skills;
    }
    .driving-lesson-worked-skill {
      @include free-text-heading(7);
      align-items: center;
      border: $free-border-size-thick solid map.get($free-palette-neutral, 100);
      border-radius: 50%;
      display: flex;
      height: 2.4rem;
      justify-content: center;
      line-height: normal;
      margin-bottom: $free-margin-stack-xs;
      padding: $free-padding-xs;
      text-align: center;
      width: 2.4rem;

      @each $color, $value in $driving-skills-colors {
        &.driving-lesson-worked-skill-#{$color} {
          border-color: $value;
        }
      }
    }
    .driving-lesson-worked-skill-mock-exam {
      background-color: map.get($free-palette-neutral, 700);
      border-color: map.get($free-palette-neutral, 700);
      color: $free-color-white;
    }
    .driving-lesson-signatures {
      align-items: center;
      column-gap: $free-margin-inline-m;
      display: flex;
      grid-area: signatures;
      margin-bottom: $free-margin-stack-xs;
    }
    .driving-lesson-signature {
      @include free-text-heading(7);
      color: map.get($free-palette-neutral, 200);
    }
    .driving-lesson-signature-signed {
      color: $free-color-success;
    }
    .driving-lesson-status-failed {
      color: map.get($free-palette-error, 700);
      grid-area: failed;
      padding-bottom: $free-padding-xs;
    }
    .driving-lesson-date {
      @include free-text-heading(6, $free-font-weight-medium);
      grid-area: lessonDate;
    }
    .driving-lesson-time {
      @include free-text-heading(6);
      grid-area: lessonTime;
    }
    .driving-lesson-duration {
      @include free-text-heading(6);
      grid-area: lessonDuration;
    }
    .student-training-book-driving-lesson-card-toggles {
      display: flex;
      grid-area: toggles;
      justify-self: center;
    }
  }

  .student-training-book-driving-lesson-card-notes {
    border-top: $training-book-driving-lesson-card-separator-size solid $training-book-driving-lesson-card-separator-color;
    display: flex;
    flex-direction: column;
    margin-top: $free-margin-stack-s;
    padding-top: $free-padding-s;
    row-gap: $free-margin-stack-xs;

    .student-training-book-driving-lesson-card-note {
      display: flex;
      flex-direction: column;

      .student-training-book-driving-lesson-card-notes-label {
        @include free-text-heading(6, $free-font-weight-medium);
      }

      p {
        white-space: pre-wrap;
      }
    }
  }

  &.driving-lesson-done {

    .driving-lesson-date,
    .driving-lesson-time,
    .driving-lesson-duration {
      color: map.get($free-palette-neutral, 400);
    }
  }

  .student-training-book-driving-lesson-card-details,
  .driving-lesson-card-details-mock-exam {
    border-top: $training-book-driving-lesson-card-separator-size solid $training-book-driving-lesson-card-separator-color;
    margin-top: $free-margin-stack-s;
    padding-top: $free-padding-s;
  }
  .student-training-book-driving-lesson-card-details-title {
    @include free-text-heading(6, $free-font-weight-medium);
    margin-bottom: $free-margin-stack-xs;
  }

  .student-driving-lesson-skills {
    margin-bottom: $free-margin-stack-s;

    .student-driving-lesson-skills-tabs {
      align-items: flex-end;
      column-gap: $free-margin-inline-s;
      display: flex;
      padding-left: $free-padding-xs;
      padding-right: $free-padding-xs;

      .student-driving-lesson-skill-tab {
        border-radius: $free-border-radius-m;
      }
    }
    .student-driving-lesson-skills-tabs-active .student-driving-lesson-skill-tab {
      border-radius: $free-border-radius-m $free-border-radius-m 0 0;
    }
    .student-driving-lesson-skills-tabs-tab {
      flex: 1 1 0px;
    }
    .student-driving-lesson-skill-tab {
      cursor: pointer;
      display: flex;
      justify-content: center;
      padding: $free-padding-squish-s;

      &.student-driving-lesson-skill-tab-active {
        padding-bottom: $free-padding-s;
      }

      @each $color, $value in $driving-skills-colors {
        &.student-driving-lesson-skill-#{$color} {
          background-color: $value;
        }
      }
    }
    .student-driving-lesson-skill-number {
      @include free-text-heading(6, $free-font-weight-medium);
    }
    .student-driving-lesson-skill-tab-content {
      border: $free-border-size-thick solid transparent;
      border-radius: $free-border-radius-m;
      padding: $free-padding-stretch-s;

      @each $color, $value in $driving-skills-colors {
        &.student-driving-lesson-skill-#{$color} {
          border-color: $value;
        }
      }
    }
    .student-driving-lesson-skill-legend {
      display: flex;
      gap: $free-margin-inline-s;
      justify-content: end;
      margin-bottom: $free-margin-stack-xs;
      opacity: 0.75;

      div {
        align-items: center;
        display: flex;
        column-gap: $free-margin-inline-s;
        justify-content: flex-end;
      }
    }
    @media all and (min-width: $free-breakpoint-m) {
      .student-driving-lesson-skill-legend {
        column-gap: $free-margin-inline-s;
        display: flex;
        justify-content: flex-end;

        div {
          justify-content: flex-start;
        }
      }
    }
    .student-driving-lesson-skill-name {
      @include free-text-heading(6, $free-font-weight-medium);
      margin-bottom: $free-margin-stack-xs;
    }
    .student-driving-lesson-sub-skills {
      display: flex;
      flex-direction: column;
      row-gap: $free-margin-stack-xs;
    }
    .student-driving-lesson-sub-skill {
      align-items: flex-start;
      display: flex;
      column-gap: $free-margin-inline-s;
    }
    .student-driving-lesson-sub-skill-number {
      min-width: 2.4rem;
    }
    .student-driving-lesson-sub-skill-name {
      flex-grow: 1;
    }
    .student-driving-lesson-sub-skill-status-control:not(.disabled) {
      cursor: pointer;
    }
    .student-driving-lesson-sub-skill-status {
      background-color: $free-color-white;
      border: $free-border-size-normal solid $free-color-black;
      border-radius: $free-border-radius-s;
      display: inline-block;
      height: 2.4rem;
      position: relative;
      min-width: 2.4rem;
      max-width: 2.4rem;

      &.student-driving-lesson-sub-skill-status-broached {
        background: linear-gradient(
            to bottom right,
            transparent calc(50% - 1px),
            $free-color-black calc(50% - 1px),
            $free-color-black calc(50% + 1px),
            transparent calc(50% + 1px)
        );
      }
      &.student-driving-lesson-sub-skill-status-covered {
        background:
          linear-gradient(
              to bottom right,
              transparent calc(50% - 1px),
              $free-color-black calc(50% - 1px),
              $free-color-black calc(50% + 1px),
              transparent calc(50% + 1px)
          ),
          linear-gradient(
              to bottom left,
              transparent calc(50% - 1px),
              $free-color-black calc(50% - 1px),
              $free-color-black calc(50% + 1px),
              transparent calc(50% + 1px),
          );
      }
      &.student-driving-lesson-sub-skill-status-assimilated::after {
        background: $free-color-black;
        border-radius: $free-border-radius-s;
        content: '';
        position: absolute;
        bottom: 2px;
        left: 2px;
        right: 2px;
        top: 2px;
      }
    }
  }

  .driving-lesson-notes-view p {
    margin-top: $free-margin-stack-xs;
    white-space: pre-wrap;
  }

  .driving-lesson-notes-edit,
  .driving-lesson-schema-edit {
    display: flex;

    .free-btn-label {
      color: $free-color-neutral;
    }
  }

  .driving-lesson-notes-actions,
  .driving-lesson-schema-actions {
    display: grid;
    gap: $free-margin-stack-s;
    grid-template-columns: min-content auto;
    margin-top: $free-margin-stack-xs;
  }

  .student-driving-lesson-schemas {
    margin-top: $free-margin-stack-s;
  }

  .driving-lesson-schema-wrapper {
    column-gap: 4.2rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, $driving-lesson-schema-image-width);
    row-gap: $free-margin-stack-m;

    .driving-lesson-schema img {
      aspect-ratio: $driving-lesson-schema-image-aspect-ratio;
      height: $driving-lesson-schema-image-height;
      width: auto;
    }
  }

  .driving-lesson-schema-delete-button {
    position: absolute;
    right: math.div($free-button-height-m, -2);
    top: math.div($free-button-height-m, -2);
  }

  .driving-lesson-schema-view .driving-lesson-schema img {
    cursor: zoom-in;
  }

  .driving-lesson-schema-edit-wrapper:not(.driving-lesson-schema-edit-wrapper-separator) {
    //margin-top: $free-margin-stack-s;
  }

  .driving-lesson-schema-edit-wrapper-separator {
    padding-top: $free-padding-s;
    border-top: $free-border-size-normal solid map.get($free-palette-neutral, 50);
    margin-top: $free-margin-stack-s;
  }

  .driving-lesson-schema-enlarged img {
    cursor: zoom-out;
    max-width: $driving-lesson-schema-enlarged-max-width;
  }

  .driving-lesson-schema,
  .driving-lesson-schema-enlarged {
    position: relative;
    display: grid;
    place-content: center;

    .driving-lesson-schema-delete,
    .driving-lesson-schema-enlarged-close {
      position: absolute;
      right: $driving-lesson-schema-delete-offset;
      top: $driving-lesson-schema-delete-offset;

      .free-btn-icon {
        font-size: $driving-lesson-schema-delete-size;
      }
    }
  }

  .student-lesson-schema-type-category-empty,
  .student-lesson-schema-type-category {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: $free-margin-stack-s;
  }

  .student-lesson-schema-type-category-empty-label,
  .student-lesson-schema-type-category-label {
    @include free-text-heading(6);
    margin-bottom: $free-margin-stack-xs;
  }

  .student-driving-lesson-schema-type-list {
    align-items: center;
    display: grid;
    grid-template-columns: 4rem 1fr 4rem;

    .student-driving-lesson-schema-type-list-content {
      display: flex;
      flex-direction: row;
      gap: $free-margin-stack-s;
    }

    .student-lesson-schema-type-figure-empty,
    .student-lesson-schema-type-figure {
      display: grid;
      gap: $free-margin-stack-xs;
      height: fit-content;
      width: $student-lesson-schema-type-figure-height;
      justify-items: center;
    }

    .student-lesson-schema-type-image-empty,
    .student-lesson-schema-type-image {
      aspect-ratio: $driving-lesson-schema-image-aspect-ratio;
      cursor: pointer;
      object-fit: fill;
      width: $student-lesson-schema-type-figure-width;
    }

    .student-lesson-schema-type-image-empty {
      outline: solid 1px black;
    }

    .student-lesson-schema-type-image-text-empty {
      height: $free-body-line-height-regular;
    }

    figcaption {
      text-align: center;
    }
  }

  .training-book-driving-lesson-card-sign-wrapper {
    padding-top: $free-padding-s;

    ul {
      list-style: inside;
    }
  }
  .training-book-driving-lesson-card-sign {
    margin-top: $free-margin-stack-xs;
  }

  @media all and (min-width: $free-breakpoint-m)
  {
    .student-lesson-schema-type-category-empty,
    .student-lesson-schema-type-category {
      align-items: flex-start;
    }

    .student-training-book-driving-lesson-card-main {
      align-items: center;
      column-gap: 0;
      grid-template-areas:
        "lessonType lessonDate lessonDate skills actions toggles"
        "trainer lessonTime lessonDuration signatures actions toggles";
      grid-template-columns: 22.4rem 8rem 8.8rem 1fr 4rem min-content;

      .driving-lesson-trainer {
        padding-bottom: 0;
      }
      .driving-lesson-worked-skills {
        justify-content: center;
      }
      .driving-lesson-worked-skill {
        margin-bottom: 0;
      }
      .driving-lesson-signatures {
        margin-bottom: 0;
        justify-content: center;
      }
      .driving-lesson-status-failed {
        padding-bottom: 0;
        text-align: center;
      }
    }

    &.driving-lesson-failed .student-training-book-driving-lesson-card-main {
      grid-template-areas:
        "lessonType lessonDate lessonDate failed actions"
        "trainer lessonTime lessonDuration failed actions";
    }
  }

  @media all and (max-width: $free-breakpoint-m)
  {
    .driving-lesson-schema-actions {
      justify-content: center;
      justify-items: center;

      .driving-lesson-schema-upload {
        margin: $free-margin-stack-s 0;

        .free-btn {
          flex-direction: column;

          .free-icon {
            @include free-text-heading(4);
            margin-left: 0;
          }
        }
      }
    }

    .driving-lesson-schema-wrapper .driving-lesson-schema img {
      height: 100%;
      width: 100%;
    }
  }
}
