/** Component: Driving Licence Type Select */

.train-driving-licence-type-select {
  display: flex;
  flex-direction: column;

  .free-form-label {
    margin-bottom: $free-text-input-label-spacing;
  }

  .train-driving-licence-type-select-box {
    display: flex;
    flex-grow: 1;
    position: relative;
  }

  .train-driving-licence-type-select-field {
    display: flex;
    background-color: $free-color-white;
    flex-grow: 1;
    justify-content: space-between;
    width: 23.2rem;
  }

  .train-driving-licence-type-select-field-high {
    box-shadow: $free-box-shadow-medium;
  }

  .train-driving-licence-type-select-field-select {
    border: $free-border-size-normal solid $free-form-color-default-input;
    border-radius: $free-border-radius-m;

    &:focus {
      border-color: $free-form-color-focused;
    }

    &:hover  {
      border-color: $free-form-color-hover;
    }

    &[disabled] {
      border-color: $free-form-color-disabled;
    }

    &:active {
      border-color: $free-form-color-pressed;
    }
  }

  .train-driving-licence-type-select-menu-icon {
    color: $free-color-primary-main;
  }

  .train-driving-licence-type-select-box-label {
    font-weight: $free-font-weight-regular;
  }

  .train-driving-licence-type-select-list {
    display: flex;
    background: $free-color-white;
    border-radius: $free-border-radius-l;
    box-shadow: $free-box-shadow-medium;
    flex-direction: column;
    left: 0;
    margin-top: $free-margin-stack-xs;
    min-width: 28.8rem;
    max-width: 28.8rem;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 10;
  }

  .train-driving-licence-type-select-group {
    display: flex;
    flex-wrap: wrap;
    gap: $free-margin-inline-s;
    padding: $free-padding-squish-m;
  }

  .train-driving-licence-type-select-item {
    @include free-text-heading(6);
    border-radius: $free-border-radius-s;
    cursor: pointer;
    display: inline-block;
    min-width: 4.8rem;
    padding: 0 $free-padding-xs;
    text-align: center;
    white-space: nowrap;

    @each $color, $value in $train-driving-licence-type-colors {
      &.driving-licence-#{$color} {
        background-color: $value;
      }
    }

    &.train-driving-licence-type-select-item-selected {
      border: $free-border-size-thick solid $free-color-black;
    }
  }
}
