/** Number Input */

.free-number-input {

  .free-text-input-box {
    width: $free-number-input-box-width-number;
  }

  input {
    text-align: right;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

/** Price Input */

.free-price-input .free-number-input .free-text-input-box {
  width: $free-number-input-box-width-price;
}

/** Rate Input */

.free-rate-input .free-number-input .free-text-input-box {
  width: $free-number-input-box-width-rate;
}

/** Distance Input */

.free-distance-input .free-number-input .free-text-input-box {
  width: $free-number-input-box-width-distance;
}

/** Duration Input */

.free-duration-input {

  .free-duration-input-hours .free-number-input .free-text-input-box {
    width: $free-number-input-box-width-hours;
  }

  .free-duration-input-minutes .free-number-input .free-text-input-box {
    width: $free-number-input-box-width-minutes;
  }

  .free-duration-inputs {
    display: flex;
  }

  .free-duration-input-hours {
    margin-right: $free-margin-inline-m;
  }
}
