@use 'sass:map';

/** Headings mixins **/

@mixin free-text-heading-base() {
  font-family: $free-font-family-heading;
}

@mixin free-text-heading($heading-level, $font-weight: false) {
  @include free-text-heading-base();
  font-size: map.get($free-heading-font-sizes, $heading-level);
  @if $font-weight {
    font-weight: $font-weight;
  }
  line-height: map.get($free-heading-line-heights, $heading-level);
}

/** Body mixins */

@mixin free-text-body-base() {
  font-family: $free-font-family-body;
  font-size: $free-body-font-size-regular;
  line-height: $free-body-line-height-regular;
}

@mixin free-text-body-size($size, $font-weight: false) {
  @include free-text-body-base();
  font-size: map.get($free-body-font-sizes, $size);
  @if $font-weight {
    font-weight: $font-weight;
  }
  line-height: map.get($free-body-line-heights, $size);
}

/** Font-weight mixins */

@mixin free-text-weight($weight) {
  font-weight: map.get($free-font-weights, $weight);
}

/** Text max height */

@mixin free-max-height-text-body($num-lines, $size: 'regular') {
  max-height: $num-lines * map.get($free-body-line-heights, $size);
  overflow-y: hidden;
}
