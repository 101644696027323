/** Structure */

// Generic
$btn_border_size: 0;
$btn_br: $br_infinite;
$btn_fc: $color_blue_black;
$btn_cursor: pointer;
$btn_p_x: $spacing_s;
$btn_p_y: $spacing_base;
$btn_p_x_s: $padding_sm;
$btn_p: $btn_p_y $btn_p_x;

// State
$btn_disabled_opacity: 0.7;


/** Skin */

$btn_1_fs: 1.6rem;
$btn_1_fw: $fw_normal;
$btn_1_lh: 2.4rem;

$btn_2_fs: 1.6rem;
$btn_2_fw: $fw_medium;
$btn_2_lh: 2.4rem;


/** Mixin */

@mixin btn() {
  background-color: transparent;
  border: $btn_border_size solid transparent;
  border-radius: $btn_br;
  color: $btn_fc;
  cursor: $btn_cursor;
  display: inline-block;
  padding: $btn_p;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;

  @media all and (max-width: 1024px) {
    padding: $btn_p_y $btn_p_x_s;
  }
}

@mixin btn-1() {
  font-size: $btn_1_fs;
  font-weight: $btn_1_fw;
  line-height: $btn_1_lh;
}

@mixin btn-2() {
  font-size: $btn_2_fs;
  font-weight: $btn_2_fw;
  line-height: $btn_2_lh;
}

@mixin btn-solid() {
  @each $color, $value in $theme_colors {
    &.#{$color} {
      background-color: nth($value, 1);
      color: nth($value, 2);
    }
  }
}

@mixin btn-outline() {
  @each $color, $value in $theme_colors {
    &.#{$color} {
      background-color: transparent;
      border: 1px solid nth($value, 1);
      color: nth($value, 1);
    }
  }
}

@mixin btn-link() {
  @each $color, $value in $theme_colors {
    &.#{$color} {
      background-color: transparent;
      color: nth($value, 1);
    }
  }
}


