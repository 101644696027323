/** Spacer */

$spacing_base: 1rem;                    // 10px

$spacing_xxs:   $spacing_base * 0.3;
$spacing_xs:    $spacing_base * 0.6;    // 6px
$spacing_ms:    $spacing_base * 0.8;
$spacing_s:     $spacing_base * 1.2;    // 12px
$spacing_m:     $spacing_base * 1.4;    // 14px
$spacing_l:     $spacing_base * 2.0;    // 20px
$spacing_xl:    $spacing_base * 4.0;    // 20px
$spacing_xxl:   $spacing_base * 5.0;    // 50px


/** Margin */

$margin_xs: 0.4rem;
$margin_s: 0.8rem;
$margin_sm: 1.6rem;
$margin_m: 2rem;
$margin_sl: 2.4rem;
$margin_l: 3.2rem;
$margin_xl: 4.8rem;
$margin_xxl: 9.6rem;


/** Padding */

$padding_xs: 0.4rem;
$padding_s: 0.8rem;
$padding_sm: 1.6rem;
$padding_m: 2rem;
$padding_sl: 2.4rem;
$padding_l: 3.2rem;
$padding_xxl: 6.4rem;
