/** Page: Auth Change Password */

#pageAuthChangePassword {

  .auth-change-password-wrapper {
    margin: 0 auto;
    max-width: $free-breakpoint-m;
    min-width: $free-breakpoint-s;
    padding: 0 $free-padding-s;
  }

  .auth-change-password-header {
    margin-bottom: $free-margin-stack-m;
    padding-top: $free-padding-l;

    h1 {
      @include free-text-heading(4, $free-font-weight-medium);
      margin-bottom: $free-margin-stack-l;
    }
  }

  .auth-change-password-form-submit {
    margin-top: $free-margin-stack-l;
  }
}
