/** Component: Driving Licence Type Badge */

.train-driving-licence-type-badge {
  @include free-text-heading(6);
  border-radius: $free-border-radius-s;
  display: inline-block;
  padding: 0 $free-padding-xs;
  text-align: center;
  white-space: nowrap;

  @each $color, $value in $train-driving-licence-type-colors {
    &.driving-licence-#{$color} {
      background-color: $value;
    }
  }
}
