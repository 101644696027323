/**
  DESIGN SYSTEM
  Freedom by Klaxo: Trainer Specific
*/

/** Variables */

@import "variables/color";

/** Cards */

@import "cards/card-training-book-driving-lesson";

/** Components */

@import "components/training-availabilities-calendar";
@import "components/student-driving-lesson-schema-drawing";
@import "components/driving-licence-type-badge";
@import "components/driving-licence-type-select";
