/** Dropdown */

.dropdown {
  position: relative;

  &.dropdown-tainted {

    .dropdown-toggle {
      padding-bottom: $dropdown_tainted_toggle_p;
      padding-top: $dropdown_tainted_toggle_p;

      .dropdown-title {
        color: $dropdown_tainted_title_color;
        font-size: $dropdown_tainted_title_fs !important;
        font-weight: $dropdown_tainted_title_fw;
      }
    }
  }

  .dropdown-toggle {
    align-items: center;
    color: $dropdown_toggle_color;
    display: flex;
    padding: $dropdown_toggle_p;
    justify-content: space-between;

    .icon {
      color: $dropdown_toggle_icon_color;
      margin-left: $dropdown_toggle_m;
    }

    .dropdown-label {
      display: flex;
      flex-direction: column;

      .dropdown-title,
      .dropdown-subtitle {
        font-size: $dropdown_toggle_fs;
        font-weight: $dropdown_toggle_fw;
      }
    }
  }

  .dropdown-menu {
    background-color: $dropdown_menu_bg_color;
    border-radius: $dropdown_menu_br;
    box-shadow: $dropdown_menu_box_shadow;
    display: block;
    left: 0;
    position: absolute;
    top: 100%;
    z-index: 999;
    min-width: 100%;

    &.left {
      right: 0;
      left: auto;
    }

    .dropdown-item {
      display: block;

      &:first-of-type {
        border-radius: $dropdown_menu_br $dropdown_menu_br 0 0;
      }

      &:last-of-type {
        border-radius: 0 0 $dropdown_menu_br $dropdown_menu_br;
      }

      &:hover {
        background-color: $dropdown_item_hover_bg_color;
      }

      &.selected a .icon:last-child {
        color: $dropdown_item_selected_color;
        margin-left: $spacing_m;
      }

      a {
        color: $dropdown_item_color;
        display: flex;
        justify-content: space-between;
        padding: $dropdown_item_p;
        white-space: nowrap;
      }
    }
  }
}

.select-driving-licence-type-groups-dropdown {
  align-items: center;
  display: flex;

  .selected-groups {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }

  .dropdown-toggle {
    border: solid 1px $color_ice;
    border-radius: $padding_s;
    flex-grow: 1;
    position: relative;

    &.dropdown-toggle-disabled {
      background-color: $color_light_gray;
      cursor: not-allowed;
    }

    .dropdown-menu {
      background: $color_white;
      border-radius: $padding_s;
      box-shadow: $box_shadow_m;
      left: 0;
      margin-top: $margin_xs;
      overflow: hidden;
      position: absolute;
      right: 0;
      z-index: 1;
    }

    .dropdown-title {
      margin-right: $margin_sm;
    }

    .dropdown-group {
      padding: $padding_s;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
    }

    .dropdown-group:hover {
      background-color: $color_ice;
    }

    .icon {
      color: $color_glacier_bleu;
      padding-left: $padding_m;
    }
  }
}
