@use "sass:math";

/** Page: Planning */

$planning_grid_hour_h: 6.4rem;
$planning_grid_cell_h: math.div($planning_grid_hour_h, 12);

$planning_hours_w: 3rem;
$planning_hours_p: $padding_s;
$planning_slot_details_w: 32rem;

$planning_wrapper_p: ($padding_sl + $panel_padding_x);

#pagePlanning {
  padding-bottom: $padding_sl;

  .planning-wrapper {
    margin: auto;
    padding: 0 $padding_sl;
  }

  .planning-main {
    margin-bottom: $margin_sl;
  }

  .planning {
    position: relative;
  }

  .planning-header {
    background-color: $color_white;
    z-index: 1;
    position: relative;

    &.fixed {
      position: fixed;
      top: 0;
      padding-top: $padding_s;
      z-index: 4;
    }

    .planning-header-bg {
      position: absolute;
      top: 0;
      bottom: 0;
      right: -1 * $planning_wrapper_p;
      left: -1 * $planning_wrapper_p;
      background-color: $color_white;
    }
  }

  .planning-menu {
    display: flex;
    margin-bottom: $margin_sm;
    position: relative;
    z-index: 2;

    .planning-hour {
      height: auto;
    }
  }

  .planning-menu-row {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
  }

  .planning-student {
    min-width: 27.4rem;

    .form-group {
      margin-bottom: 0;
    }

    .input-group {
      border-color: #66C8D2;
    }
  }

  .planning-display-type-settings {
    align-items: center;
    column-gap: $margin_sm;
    display: flex;
  }

  .planning-day-details {
    align-items: baseline;
    column-gap: $margin_s;
    display: flex;
    justify-content: center;
    padding: $padding_s 0;
  }

  .planning-day-name {
    font-size: $fs_m;
  }

  .planning-day-date {
    color: $link_color;
    cursor: pointer;
    font-size: $fs_l;
    text-decoration: $link_text_decoration;

    &:hover {
      color: $link_hover_color;
      text-decoration: underline;
    }
  }

  .planning-day-date-today {
    font-weight: $fw_medium;
    text-decoration: underline;
  }

  .planning-header-columns {
    position: relative;
    display: flex;
    z-index: 1;

    .planning-hour {
      height: auto;
    }

    .planning-row {
      border-bottom: 2px solid $color_gray;
    }

    .planning-col {
      align-items: center;
      justify-content: center;
      padding: $padding_s 0;
    }
  }

  .planning-body {
    display: flex;
  }

  .planning-hours {
    display: flex;
    flex-direction: column;
    width: $planning_hours_w;
    padding-right: $planning_hours_p;
  }

  .planning-hour {
    height: $planning_grid_hour_h;

    span {
      display: block;
      font-size: $fs_ms;
      line-height: $fs_ms;
      position: relative;
      top: -1 * math.div($fs_ms, 2);
      text-align: right;
    }
  }

  .planning-row {
    display: flex;
    flex-grow: 1;
  }

  .planning-col {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
  }

  .planning-grid-column {
    border-left: 1px solid $color_medium_gray;
    position: relative;

    .planning-grid-hour:not(:last-of-type) {
      border-bottom: 1px solid $color_warm_gray;
    }
  }

  .planning-body .planning-hour:not(:last-of-type) {
    border-bottom: 1px solid transparent;
  }

  .planning-grid-cell {
    height: $planning_grid_cell_h;
  }

  .planning-caption {
    margin-top: $margin_sl;
    display: flex;

    .type-items {
      margin-left: $margin_sm;
    }

    .caption-item {
      display: flex;
      align-items: center;
      margin-bottom: $margin_sm;

      .caption-item-color {
        border-radius: $br_l;
        min-width: 6rem;
        margin-right: $margin_s;
        padding: $padding_xs $padding_s;

        @each $color, $value in $driving_licences_colors {
          &.caption-item-driving-licence-#{$color} {
            background-color: $value;
          }
        }

        @each $color, $value in $training_slot_colors {
          &.caption-item-#{$color} {
            background-color: $value;
          }
        }
      }

      .caption-item-driving-lesson-failed {
        color: $color_error;
      }
    }
  }

  @media all and (max-width: 1024px) {
    .planning-menu-row {
      align-items: center;
      display: grid;
      grid-row-gap: $margin_s;
      grid-template-areas:
        "date displayTypeSettings"
        "student .";
    }
    .planning-date-navigator {
      grid-area: date;
    }
    .planning-student {
      grid-area: student;
    }
    .planning-display-type-settings {
      grid-area: displayTypeSettings;
      justify-content: flex-end;
      justify-items: flex-end;
    }
  }

  @media all and (max-width: 719px) {
    .planning-menu-row {
      grid-template-areas:
        "date displayTypeSettings"
        "student student";
    }
  }

  @media all and (max-width: 600px)
  {
    .planning-caption {
      flex-direction: column;

      .type-items {
        margin-left: 0;
      }
    }
  }

  @media all and (max-width: 475px)
  {
    $planning_wrapper_p: ($padding_s + $panel_padding_x);

    .planning-wrapper {
      padding: 0 $padding_s;
    }
  }
}

.page-mobile {

  .planning-slot.planning-slot-expanded,
  .planning-slot.planning-slot-highlighted {
    z-index: auto;
  }

  .planning-slot .planning-slot-details-container {
    background-color: $panel_bg_color;
    bottom: 0;
    left: 0;
    position: fixed;
    overflow-y: auto;
    right: 0;
    top: 0;
    z-index: 10;

    .panel {
      box-shadow: none;
    }
  }
}

.planning-slot {
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  align-items: stretch;
  cursor: pointer;
  visibility: hidden;

  &.planning-slot-past .planning-slot-body-content {
    opacity: 0.75;
  }

  &.cdk-drag-placeholder,
  &.cdk-drag-preview {
    display:none;
  }
  &.planning-slot-placeholder .planning-slot-body {
    border: 0;
    box-shadow: $planning_slot_expanded_bs;
    z-index: 2;
  }
  &.planning-slot-former {
    opacity: 0.4;
  }
  &.planning-slot-dropping {
    opacity: 0;
  }

  @each $color, $value in $training_slot_colors {
    &.planning-slot-#{$color} .planning-slot-body-content {
      background-color: $value;
    }
  }

  @each $color, $value in $driving_licences_colors {
    &.planning-slot-driving-lesson.planning-slot-driving-licence-#{$color} .planning-slot-body-content {
      background-color: $value;
    }
  }

  .planning-slot-body {
    position: relative;
    flex-grow: 1;
    display: flex;
    border-radius: $br_l;
    border-bottom: 1px solid $color_white;
    border-top: 1px solid $color_white;
    overflow-y: hidden;
  }

  .planning-slot-body-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $color_white;
  }

  .planning-slot-body-content {
    position: relative;
    flex-grow: 1;
    padding: $padding_xs;
    min-width: 0;
  }

  .planning-slot-activity-type {
    font-size: $fs_ms;
  }

  .planning-slot-student {
    align-items: center;
    display: flex;

    * {
      font-size: $fs_ms;
    }

    .planning-slot-student-info {
      display: flex;
      flex-grow: 1;
      min-width: 0;
    }

    .planning-slot-student-name {
      overflow: hidden;
      white-space: nowrap;

      &.driving-lesson-failed {
        color: $color_error;
      }
    }

    .planning-slot-student-more {
      margin-left: $margin_xs;
    }

    .planning-slot-student-link {
      margin-left: $margin_s;
      color: $color_turquoise_dark;
    }
  }

  .planning-slot-status {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: $margin_xs;

    > span.icon {
      font-size: $fs_ms;
    }

    .planning-slot-hours {
      font-size: $fs_base;
    }

    .planning-slot-hours-lesson-type {
      align-items: center;
      display: flex;
    }

    .planning-slot-driving-lesson-type {
      background-color: $color_shocking;
      border-radius: $br_infinite;
      color: #0c343d;
      font-size: $fs_base;
      margin-left: $margin_s;
      margin-right: auto;
      padding: $padding_xs $padding_s;
    }

    .planning-slot-driving-lesson-type-training {
      background-color: #ebfdff;
    }
  }

  .planning-slot-driving-licence-groups {
    margin-bottom: $margin_xs;
    font-size: $fs_ms;
  }

  .planning-slot-meeting-point {
    margin-bottom: $margin_xs;
    font-size: $fs_ms;
  }

  .planning-slot-notes {
    font-size: $fs_ms;
    white-space: pre-line;
  }

  &.planning-slot-expanded {
    z-index: 2;

    .planning-slot-body {
      border: 0;
      box-shadow: $planning_slot_expanded_bs;
    }
  }

  &.planning-slot-highlighted {
    z-index: 1;

    .planning-slot-body {
      border-left: 8px solid $color_shocking;
      box-shadow: $planning_slot_expanded_bs;
    }
  }

  .planning-slot-details-container {
    position: absolute;
    cursor: default;

    &.planning-slot-details-container-left {
      padding-right: $padding_s;
      right: 100%;
      top: 0;
    }

    &.planning-slot-details-container-right {
      left: 100%;
      padding-left: $padding_s;
      top: 0;
    }

    &.planning-slot-details-container-bottom {
      top: 100%;
      padding-top: $padding_xs;
      left: 50%;
      transform: translateX(-1 * math.div($planning_slot_details_w, 2));
    }

    .planning-slot-details {
      min-width: $planning_slot_details_w;
    }

    .panel {
      margin-top: 0;
      box-shadow: $planning_slot_details_bs;

      .header {
        padding-bottom: 0;
      }
    }

    .planning-slot-details-header-actions {
      display: flex;
      justify-content: right;

      .planning-slot-details-close {
        color: $color_dark_gray;
        font-size: $fs_m;
        padding-right: 0;
      }
    }
  }

  .planning-slot-types-menu {
    display: flex;
    justify-content: space-between;
    margin-bottom: $margin_sm;

    .planning-slot-type {
      padding: $padding_s $padding_sm;

      &.active {
        cursor: default;
        color: $color_dark;

        @each $color, $value in $training_slot_colors {
          &.planning-slot-type-#{$color} {
            background-color: $value;
          }
        }
      }
    }
  }

  .planning-slot-date-time {
    margin-bottom: $margin_sm;

    .planning-slot-date {
      margin-bottom: $margin_s;
    }

    .planning-slot-date-time-view {
      display: flex;
    }

    .planning-slot-date-time-range {
      margin-right: $margin_sm;
    }

    .planning-slot-date-time-form {

      form {
        display: flex;
        justify-content: flex-start;

        .form-group {
          margin-bottom: 0;
        }
      }

      .planning-slot-date-time-start {
        margin-right: $margin_s;
      }

      .confirm-actions {
        margin-left: $margin_sm;
      }
    }
  }

  .planning-slot-form-places {
    margin: $margin_sm 0;
  }

  .planning-slot-num-places-input {
    display: flex;
    margin-bottom: $margin_s;

    .planning-slot-num-places {
      min-width: 7rem;
    }
  }

  .student-error-msg {
    color: $color_error;
  }

  .driving-session-student {
    display: flex;
    justify-content: space-between;

    .driving-session-student-input {
      flex-grow: 1;
    }

    .driving-session-student-submit,
    .driving-session-student-actions {
      padding-left: $margin_s;
    }
  }

  .planning-slot-department-code {
    margin-bottom: $margin-sm;
  }

  .planning-slot-actions,
  .planning-slot-swap-form-actions {
    display: flex;
    justify-content: space-between;

    button {
      flex: 1 1 0;

      &:first-of-type {
        margin-right: $margin_sm;
      }
    }
  }

  .planning-slot-action {
    display: flex;
    justify-content: flex-end;
  }

  .planning-slot-free-recurring-actions {
    display: flex;
    flex-direction: column;

    button:not(:last-of-type) {
      margin-bottom: $margin-s;
    }
  }

  .modal-content {
    top: 100%;
    transform: translate(-50%, 0);
  }

  @media all and (max-width: 1520px)
  {
    .planning-slot-status {
      align-items: flex-start;

      .planning-slot-hours-lesson-type {
        flex-direction: column;
      }

      .planning-slot-driving-lesson-type {
        margin-left: 0;
        margin-top: $margin_xs;
      }
    }
  }
}
