/** Period Input */

.free-period-input {

  .free-form-label {
    margin-bottom: $free-date-input-label-spacing;
  }

  .free-form-helper-text,
  .free-form-invalid-text {
    margin-top: $free-form-invalid-text-spacing;
  }

  .free-period-inputs {
    display: flex;
  }

  .free-period-input-day {
    margin-right: $free-margin-inline-s;
    min-width: 7.2rem;
  }

  .free-period-input-month {
    margin-right: $free-margin-inline-s;
    min-width: 8rem;
  }

  .free-period-input-year {
    min-width: 8.8rem;
  }

  .free-period-input-shortcut {
    display: flex;
    justify-content: center;

    button {
      @include free-text-body-size('small');
    }
  }

  .free-period-input-highlighted {
    .free-dropdown-list-box-field-select {
      border-color: $free-form-color-focused;
    }
    .free-dropdown-list-box-label {
      font-weight: $free-font-weight-bold;
    }
  }
}
